.ecom-endusers-headers{

}

.ecom-endusers-left-header{
    margin-top: 2rem;
}


.ecom-endusers-above-heading{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}


.ecom-endusers-above-heading p{
    font-size: 1.01rem;
    color: #A1A1A1;
    font-weight: 700;
    cursor: pointer;
}

.ecom-endusers-above-heading .ecom-product-active{
    color: black;
    text-decoration: underline;
}

.ecom-subcategory-activestate{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: wrap;
}

.ecom-subcategory-activestate label {
    display: flex;
    align-items: center;
}

.ecom-subcategory-newdiv{
    display: flex;
    flex-wrap: wrap;
    padding:1rem 2rem 0 0;
    justify-content: flex-end;
    align-items: center;
    gap:2rem;
}

.ecom-subcategory-addbtn{
    background-color: var(--color-blue);
    color: white;  
    padding: 7px 15px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ecom-subcategory-add{
    color: white;
    font-size: 20px;
}

.ecom-subcategory-delicon{
    font-size: 30px;
    color: red;
}

.ecom-product-left-detail-sectionwise span, .ecom-product-right-detail-sectionwise span{
    width:50%;
    word-break: break-all;
}

.ecom-product-left-detail-sectionwise,.ecom-product-right-detail-sectionwise{
   width: 50%;
}

.ecom-product-left-detail-sectionwise select{
    width: 140px;
    height: 30px;
    border-radius: 7px;
    border: 2px solid #C9C7C7;
    padding: 0 10px;
    outline: none;
    margin: 2px 0px;
}

.ecom-product-left-detail-sectionwise input, .ecom-product-right-detail-sectionwise input{
    margin: 2px 0px;
}


.ecom-rawmaterial-product-dropdown{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap:20px;
}

.ecom-rawmaterial-product-dropdown p{
    text-align: center;
    font-weight: 600;
}
.ecom-rawmaterial-product-dropdown select{
    width: 10rem;
    border: 2px solid grey;
    border-radius: 8px;
}

.ecom-rawproduct-detail-section {
    width: 50vw;
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ecom-rawproduct-detail-section p{
    display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5rem;
        margin: 0;
}


.raw-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: scroll;
  }
  
  .raw-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .raw-modal-content input{
    width: 50%;
  }
  .raw-modal-content p strong{
    border: 2px solid blue;
  }

  .raw-modal-close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .raw-close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .raw-close-button:hover {
    background-color: #0056b3;
  }

  .raw-modal-container{
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap:20px;
  }
  .raw-modal-divcontainer{
    display: flex;
    flex-direction: column;
    border: 2px solid black;
  }

.raw-modal-buttons{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}
  

.rawmaterialimagecontainer{
    border: 1px solid rgb(148, 147, 147);
    border-radius: 8px;
    width: 10rem;
}
.rawmaterialimagecontainer img{
    object-fit: contain;
}
.rawmaterialimagessscontainer{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;    
}

.rawmaterialimgsizediv{
    width: 90%;
    height: 100px;
}


.rawmaterialeditdltbtn{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.rawmaterialeditdltbtn button{
    background: none;
    cursor: pointer;
}

.rawmaterial-leftsectiondiv span{
    width: 7rem;
}

.rawmaterial-leftsectiondiv p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    /* margin: 0; */
}

.rawmaterial-leftsectiondiv input{
    width: 140px;
    height: 30px;
    border-radius: 7px;
    border: 2px solid #C9C7C7;
    padding: 0 10px;
}

.rawmaterial-leftsectiondiv select{
    width: 140px;
    height: 30px;
    border-radius: 7px;
    border: 2px solid #C9C7C7;
    padding: 0 10px;
}

.rawmaterial-rightsectiondiv p{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
}
.rawmaterial-rightsectiondiv span{
    width: 7rem;
}
.rawmaterial-rightsectiondiv input{
    width: 140px;
    height: 30px;
    border-radius: 7px;
    border: 2px solid #C9C7C7;
    padding: 0 10px;
}




.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    flex-direction: column;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light gray */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  

  
@media screen and (max-width:768px) {
    .ecom-product-left-detail-sectionwise span, .ecom-product-right-detail-sectionwise span{
        width:100%;
       
    }

    
.ecom-rawproduct-detail-section{
    flex-direction: column;
}

.rawmaterialimagecontainer{
    width: 30%;
}
}


