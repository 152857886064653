/* Orders.css */
.orders-container_customer {
    font-family: Arial, sans-serif;
    margin: 5rem 3rem;   
}

.breadcrumb_customer {
    margin-bottom: 15px;
    font-size: 14px;
    color: #555;
    margin-top: 60px;
    
}

.orders-main_customer {
    display: flex;   
}

.orderstatus_customer label{
    flex-direction: row;
}
.orderstatus_customer input{
    width:20px;
}

.orders-date-filter_customer input{
    width: 20px;
}

.orders-filter_customer {
    width: 250px;
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 25px;
    border-right: 1px solid #ddd;
}
/* .orders-filter label input{
    padding-top: 20px;
} */
.orders-filter_customer h3,
.orders-filter_customer h4 {
    margin-top: 0;
    padding: 10px;
}

.orders-date-filter_customer {
    margin-top: 20px;
    border-top: 1px solid #ddd;
}

.orders-content_customer {
    flex: 1;
    padding: 20px;
    

}

.orders-search_customer {
    display: flex;
    margin-bottom: 20px;
}

.orders-search_customer input[type="text"] {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
}

.orders-search_customer button {
    padding: 8px 16px;
    background-color: #33C71C;
    color: white;
    opacity: 67%;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.orders-list_customer .order-card_customer {
    display: flex;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    align-items: flex-start;
}
.orders-list_customer{
    cursor: pointer;
}
.order-image_customer {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    object-fit: contain;
}

.order-info_customer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-details_customer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.order-name_customer {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    margin: 0;
}

.order-price_customer {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-top: 5px;
}

.order-status_customer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    color: #555;
}

.status-indicator_customer {
    width: 8px;
    height: 8px;
    border-radius: 50%;
   
    /* margin-left: 230px; */
   
}

.status-text_customer {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px;
}

.order-description_customer {
    font-size: 12px;
    color: #777;
 
}

.multipleorder-items{
    border: 2px solid black;
}

@media screen and (max-width:768px) {
    .orders-main_customer{
        flex-direction: column;
        
    }
    .orders-container_customer{
        margin:5rem 1rem;
    }
    .orders-filter_customer{
        width: 100%;
    }
    .orders-list_customer
    .order-card_customer {
        flex-direction: column;
    }
    .order-status_customer {
      /* flex-direction: column; */
    }
    .order-status_customer p{
        word-wrap: break-word;
    }
    .order-info_customer{
        flex-direction: column;
        align-items: flex-start;
    }
    .order-status_customer{
        align-items: flex-start;
    }
}
