.address_page_customer{
    padding: 20px;
    
    
}
.step-navigation_customer {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
   .select_address_customer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
   }
   .select_address_customer h5{
   color: #87BD41;
    margin-top: 25px;
   
   }
  
  .step-item_customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    position: relative;
  }
  
  .step_customer {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
  }
  
  .step-label_customer {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
  
  /* Adjusted line */
  .line_customer {
    width: 40px;
    height: 2px;
    
    background-color: #ccc;
    position: relative;
    top: -10px; /* Aligns the line with the middle of the step circle */
    z-index: -1; /* Positions line behind the circles */
  }
  
  .product_add_main_div_customer {
    display: flex;
    gap: 20px;
    width: 100%;
    padding: 20px;
  }

  .left_side_customer{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap:20px;
  }
  
.address_content_main_div_customer{    
        display: flex;
        /* align-items: center; */
       justify-content: center;
        gap: 20px;
        width: 100%;
        padding: 2rem;
      } 

 
 /* Right side - Price Details */
 .product_right_side_customer {
    width: 30%; 
    /* padding: 20px; */
    /* border-left: 1px solid #ddd; */
    display: flex;
    flex-direction: column;
    /* margin-right: 10rem; */
    gap: 15px;
   border-left: 1px solid black;
}
/* Price Detail Section */
.price_detail_container_customers {
    padding: 20px;
   
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
    width: 100%;
    

  }
  
  .pice_detail_customer h4 {
    /* font-size: 18px; */
    margin-bottom: 15px;
  }
  
  .total_price_customer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    color:black;
  }
  .total_discount_customer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    color: #87BD41;
  }
  
  hr {
    margin: 20px 0;
    border-top: 1px solid #ddd;
  }
  
  .order-total_customer {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
  }
  
  .order-total_customer p {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  .total_discount_msg_customer p {
    margin: 0;
    font-size: 12px;
    color: rgba(144, 28, 126, 1);

    font-weight: bold;
    /* border:2px solid black; */
    margin-top: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba(199, 28, 165, 0.38);
    ;
  }
.continue_customer p{
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.continue_customer button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 10px;
    width: 100%;
    color: white;
    margin-top: 5px;
    background: #87BD41;
    border: none;
}
.continue-button_customer:active {
  background: #649e2c; /* Slightly darker shade when clicked */
  transform: translateY(2px); /* Button appears to be pressed down */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect on click */
}
.address_content_customer{
    /* height: 250px; */
    /* width: 70%; */
    padding: 20px;
    /* margin-top: 20px; */
    background: rgba(204, 234, 254, 0.67);
    border-radius: 10px;
}
.name_edit_customer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   padding: 20px;

}
.name_edit_customer p{
font-size: 14px;
font-weight: bolder;
/* margin-top: 20px; */
}
.name_edit_customer h5{
    color: #87BD41;
    border: none;
    font-size: 16px;
  
    /* margin-top: 20px; */
}
.address_para_customer{
    font-size: 12px;
    padding-top: 10px;
    padding-left: 20px;
    font-weight: 600;
   
}
.address_para_customer p{
    padding-top: 20px;
}
.deliver_customery p{
    font-size: 12px;
    margin-top: 20px;
    display: flex;
   
    align-items: center;
    justify-content: center;
}
.delivery_customer button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    color: white;
    border-radius: 10px;
    width: 100%;
    margin-top: 30px;
    background: #87BD41;
    border: none;
    
}

.select_address_customer,.name_edit_customer h5{
    cursor: pointer;
}


@media screen and (max-width:768px) {
  .address_content_main_div_customer{
    padding: 1rem;
    flex-direction: column;
  }
  .left_side_customer{
    width: 100%;
  }
  .product_right_side_customer {
    width: 100%;
    border: none;
  }
  .select_address_customer{
    flex-direction: column;
  }
}
