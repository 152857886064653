/* Navbar styling */
.navbar_customer {
    background-color: #87BD41;
    width: 100vw;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 60px;
}

/* Logo styling */
.navbar-logo_customer {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.navbar-logo_customer .logo_customer {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Navbar menu styling */
.navbar-menu_customer {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;   
}

.navbar-menu_customer li {
    position: relative;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.navbar-menu_customer a {
    text-decoration: none;
}

/* Dropdown icon styling */
.dropdown-icon_customer {
    margin-left: 5px;
}

/* Dropdown menu styling */
.dropdown-menu_customer {
    display: none;
    position: absolute;
    top: 100%;
    background-color: #fff;
    list-style: none;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 210px;
    z-index: 1000;
    border: 1px solid #ddd;
}

.dropdown-menu_customer li {
    padding: 10px;
    width: 100%;
}
.menu_list_customer{
    /* margin-left: 45rem; */
}
.menu_list_customer li{
    margin-left:0px;
    padding: 10px;
    
}

/* Add hover effect for dropdown items with full-width green background */
.dropdown-menu_customer li:hover {
    background:#33C71C;
    opacity: 70%;
   
}

/* Dropdown menu link styling */
.dropdown-menu_customer li a {
    text-decoration: none;
    color: #333;
    display: block;
    font-size: 0.9rem;
    padding: 8px 12px; /* Adjust padding for better alignment */
    width: 100%;
}

.dropdown_customer:hover .dropdown-menu_customer {
    display: block;
}

/* Profile and cart icon styling */
.profile_cart_icon_customer {
    font-size: 25px;
}

/* Ensure content isn't hidden behind the navbar */
body {
    /* padding-top: 60px; */
}

/* Sub-dropdown menu styling */
.sub-dropdown-menu_customer {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 1000;    
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Show sub-dropdown on hover */
.dropdown-menu_customer li:hover .sub-dropdown-menu_customer {
    display: block;
}


/* Style for sub-dropdown menu items */
.sub-dropdown-menu_customer li {
    padding: 5px 10px; /* Add padding for sub-items */
    list-style: none;
    font-size: 12px;
    min-width: 100px;
    background: #fff;
}

.sub-dropdown-menu_customer li a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 8px 12px;
    width: 200px;
}

/* Hover effect for sub-dropdown items with full-width green background */
.sub-dropdown-menu_customer li:hover {
    /* background:#33C71C; */
    /* opacity: 80%; */
}

/* ############## PROFILE ACCOUNT########### */
/* Profile container */
.profile-container_customer {
    position: relative;
    display: inline-block;
  }

  /* Dropdown that appears on hover */
  .profile-container_customer:hover .profile-dropdown_customer {
    display: block;
  }

  .profile-cart-icon_customer{
    font-size: 25px;
    position: relative;
  }

  .profile-cart-icon_customer + span {
    position: absolute;
    top: 16px; 
     right: 16px;
    background-color: red;
    color: white;
    font-size: 12px; /* Font size for the count */
    border-radius: 50%; /* Make it circular */
    width: 12px; /* Diameter of the circle */
    height: 12px; /* Diameter of the circle */
    display: flex;
    align-items: center;
    justify-content: center;
   
    font-weight: bold; /* Make the text bold */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional shadow */
  }

  



  .profile-dropdown_customer {
    display: none; /* Hidden by default, appears on hover */
    position: absolute;
    top: 100%; /* Position below the icon */
    /* left: 0; */
    margin-right: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px; /* Adjust width as needed */
    /* padding: 10px; */
    border-radius: 5px;
    z-index: 10;
  }
  
  /* Profile options */
  .profile-option_customer {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;    
  }
  
  .profile-option_customer:hover {
    /* background-color: #f0f0f0; */
    /* background:#33C71C; */
    /* opacity: 70%; */
  }
  
  /* Profile icon next to user info */
  .profile-icon_customer {
    font-size: 1.2em;
    margin-right: 10px;
  }
  
  /* User info (name and phone) */
  .profile-info_customer {
    display: flex;
    flex-direction: column;
  }
  
  .profile-name_customer {
    font-weight: bold;
  }
   
  .profile-phone_customer {
    font-size: 0.9em;
    color: #666;
  }
  
  /* Specific styles for options */
  .profile-option_customer:not(:first-child) {
    border-top: 1px solid #ddd;
  }
  
  .profile-option_customer:last-child {
    color: #d9534f; /* Red color for Logout option */
  }

  /* Sub-dropdown menu styling */
.sub-dropdown-menu_customer {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 500;
  background-color:#fff; /* Solid white background */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Optional, for shadow */
}

/* Show sub-dropdown on hover */
.dropdown-menu_customer li:hover .sub-dropdown-menu_customer {
  display: block;
}

/* Style for sub-dropdown menu items */
.sub-dropdown-menu_customer li {
  padding: 5px 10px; /* Add padding for sub-items */
  list-style: none;
  font-size: 12px;
  min-width: 100px;
  background: #FFF; /* Ensure background is white */
}

/* Remove opacity to prevent transparency */
.sub-dropdown-menu_customer li:hover {
  /* background: #33C71C; 
  opacity: 1;  Make hover effect solid */
}

.sub-dropdown-menu_customer li a {
  color: #333;
  text-decoration: none;
  display: block;
  padding: 8px 12px;
  width: 200px;
}

.hamburgerUser{
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: #333;
}


@media screen and (max-width:768px) {
  .profile-cart-icon_customer + span{
    top:0;
    right:0;
  }

  .navbar-logo_customer {
    
  }

  .menu_list_customer{
    
  }

  .hamburgerUser{
    display: block;
  }
  .navbar-menu_customer{
      display: none;
      flex-direction: column;
  }
  .navbar-menu_customer.openUser {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    position: absolute; /* Overlay the entire screen */
    top: 60px;
    left: 0;
    width: 100vw; /* Full width */
    background-color:white;
    z-index: 1000; /* Ensure it appears above other elements */
    padding: 0;
  }
  .menu_list_customer li{
    margin:0;
    padding: 0;
  }
  .dropdown-menu_customer {
    display: none;
  }
  .dropdown_customer.open .dropdown-menu_customer {
    display: block;
  }
}





