.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}
.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

@media (min-width: 768px){
.elementor-column.elementor-col-100, .elementor-column[data-col="100"] {
    width: 100%;
}
}

.elementor-element {
    --widgets-spacing: 20px;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 10px;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}

.elementor-widget-wrap>.elementor-element {
    width: 100%;
}

.elementor-element {
    --widgets-spacing: 20px;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}
.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

@media (min-width: 768px){
    .elementor-column.elementor-col-33, .elementor-column[data-col="33"] {
        width: 33.333%;
    }
}


.elementor-element {
    --widgets-spacing: 20px;
}