.shopping-card_customer {
    background-color: rgb(174, 171, 171);
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    flex-direction: column;
    margin-left: 10px;
    /* scroll-snap-align: start; */
    overflow-x: scroll;
}

.product-img_customer {
    max-width: 250px;
    max-height: 250px;
    margin-bottom: 40px;
}

.card-content_customer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
}

.card-header_customer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2px;
    /* margin-bottom: 10px; */
}

.product-title_customer {
    font-size: 15px;
    font-weight: bold;
    color: black;
}

.rating_customer {
    color: gold;
    display: flex;
    font-size: 12px;
}

.card-footer_customer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.price_customer {
    font-size: 14px;
    color: #87BD41;
    font-weight: bold;
}

.heading_customer {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}



.heading_customer p {
    font-weight: bold;
    font-size: 2rem;
    color: #16214E;

}

.main_div_customer {
    padding: 2rem;
    background: rgba(233, 233, 233, 1);
}

.shopping-card_customer {
    border: 2px solid rgb(214, 209, 209);
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 200px;
    min-width: 220px;
    max-width: 220px;
    /* height: 300px; */
    flex-direction: column;
    /* margin-left: 30px; */
    scroll-snap-align: start;
}

.product-img_customer {
    max-width: 217px;
    min-width: 217px;
    max-height: 200px;
    min-height: 200px;
    object-fit: cover;
    /* margin-bottom: 20px; */
}

.images_customer img{
    /* object-fit: contain; */
}

.card-content_customer {
    display: flex;
    flex-direction: column;
    /* padding: 5px; */
    width: 100%;
}

.cards-container_customer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    gap:5px;
    height: auto;
}
.heading_button_customer{
    margin: 1rem 0;
}

.heading_button_customer button{
    padding: 10px 1rem;
    background-color: var(--color-blue);
    color: white;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 2rem;
    
}
.haeding_see_btn{
    float: right;
}


.view-more-btn_customer {
    height: 30px;
    width: 100%;
    border: none;
    background-color: #3ca46b;
    color: white;
} 


@media screen and (max-width:768px) {
    .cards-container_customer{
        flex-direction: column;
       
    }

}