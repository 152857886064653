@media screen and (max-width:990px) {
    
}

.iotSol__headImg1{
    margin-left: 2rem
}
@media screen and (max-width:650px) {
    .iotSol__headImg1, h1{
       margin-top: 2rem;
    }
    .iotSol__headImg1{
        margin-left: 0;
        text-align: center;
        padding: 2%;
    }
    
    .iotSol__headImg1 img{
        padding: 0;
    }
}



