

.iotSol__head{
    display: flex;
    margin-top: 3rem;
}

.iotSol__head 
.iotSol__headImg1{
    margin-top: 3rem;
}
.iotSol__headImg1 img{
    width:20rem;
    /* margin-left: 5rem; */
}


.iotSol__head
.iotSol__headImg1{
    display: flex;
    flex-direction: column;
}

.iotSol__head
.iotSol__headImg2{
    margin-top: -3rem;
    margin-left:5rem;
   
}
.iotSol__text p{
font-weight: bold;
margin:1rem 6rem;
}

/* .iotSol__belowhead{
    display: flex;
} */

.iotSol__img{
    margin-top: 2rem;
    display: inline-block;
    /* margin-left: 6rem; */
}  

.iotSol__belowhead .iotSol__img{
    margin:0px 2rem;
    display: flex;
}

.iotSol__img img{
    line-height: 0;
    height: 18%;
    width: 16.6%;
    margin-right: -1rem;
}

/* .iotSol__below{
    background-image:url('../Images/iot_bg.png') ;
    height: 4rem;
    margin-top: -6rem;
} */
.iotSol__belowhead{
    margin-top: 2rem;
}


.iotSol__sidebar{
    position: absolute;
    right: 4%;
    margin-top: -12rem;    
}

.iotbtn button{
    margin-top: 10px;
    width: 8rem;
    height: 2.5rem;
    font-size: 1rem;
}

.iotbtn1{
    background-color: var(--color-primary);
    color:white;
    border-radius: 10px;
    border:none;
    font-weight: 500px;
    cursor: pointer;
    margin-right: 1rem;
    width: 6rem;
    text-align: center;
}

.iotbtn1:hover{
    color: black;
}
.iotbtn2{
    color:black;
    border: 1px solid black;
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer; 
    text-align: center;
    width: 6rem;
}

.iotbtn2:hover{
    color:var(--color-blue);
    border:2px solid var(--color-blue);
}

/* temp */

.itemwrapper{
    display: flex;
    flex-direction: column;
    width: 16vw;
    margin:1rem;
    height: 200px;
    z-index: 10;
     /* filter: drop-shadow(-7px -7px rgb(142, 138, 138)); */
     box-shadow: 0 0 10px 5px rgba(0,0,0,0.3);
}

.itemwrapper p{

    width:15%;
    height: auto;
    /* line-height: 120%; */
    padding: 1rem;
}


.main2 {    
    min-height: 200px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}



#bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

#bottom .back{
    background-color: black;
    filter: opacity(55%);
    width: 100%;
    color: rgb(255, 255, 255);
    padding: 6px 4px;
    text-align: center;
    cursor: pointer;
}


 .iotSol_para{
    opacity: 0;
    background-color: white;
    visibility: hidden;
   transition :0.4s ;
   height: 0;
   color:black;
   z-index: 9;

}

.iot_line{
    width: 40rem;
}


.itemwrapper:hover  .iotSol_para{
 visibility: visible;
 opacity: 1;
 background-color: white;
 padding-left: 8px;
 padding-top: 8px;
 width: 16vw;
 height: 200px;
 z-index: 10;
}


@media screen and (max-width:1300px) {
    /* .iotSol__sidebar{
        display: none;
    } */
    .itemwrapper:hover  .iotSol_para{
        width: 80vw;
        z-index: 1;
        display: none;
       }    
       .iotSol__belowhead .iotSol__img{
        margin: 0;
       }
}

@media screen and (max-width:1224px) {
     .iotSol__sidebar{
        display: none;
    }
}

@media screen and (max-width:990px) {
    .iotSol__head{
        display: block;
    }
    .iotSol__head .iotSol__headImg2 {
        margin-top: -3rem;
        margin-left: 0;
    }
    .iotSol__belowhead{
        margin-top: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .iotSol__img{
        display: flex;
        flex-direction: column;
    }
    .itemwrapper, .main2{
        width: 80vw;
    }

    .iot_img{
        margin-top: 2em;
    }

    .iot_line {
        /* width: 80rem; */
        margin: 2rem 0;
    }
    .iot_img2{
        margin-bottom: -3rem;
    }
    .iotSol__sidebar{
        display: none;
    }
    .iotSol_para{
        z-index: 1;
    }
}



@media screen and (max-width:650px ) {
    .iotSol__head{
        display: block;
    }
    .iotSol__belowhead{
        margin-top: 0.4rem;
    }
    .iotSol__img{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .itemwrapper, .main2{
        width: 80vw;

    }

    .iot_img{
        margin-top: 2em;
    }

    .iot_line {
        /* width: 80rem; */
        margin: 2rem 0;
    }
    .iot_img2{
        margin-bottom: -3rem;
    }
    .iotSol__sidebar{
        display: none;
    }
    .iotSol__text p{
        margin: 10%;
        width: 80%;
    }
}
