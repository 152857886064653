.newproduct__main{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.card-deck{
    margin-left: 2em;
    margin-right: 2em;
}

.card-deck button{
margin-left: 5%;
margin-bottom: 2rem;
}

.heading_products strong{
    margin-top: 3rem;
}

.card-deck h4{
    text-align: center;
    padding-top: 4%;
}
.card-deck:hover{
    transform: none;
}

.card-deck p{
    /* style={{textAlign:'left', paddingLeft:'2rem'}} */
    text-align: left;
    padding-left: 2rem;
}
/* style={{marginLeft:'20%', marginBottom:'1.5rem'}}  */
@media screen and (max-width:768px) {
    .heading_products{
        margin-top: 2em;
    }
    .card-deck button{
        margin-left: 10%;
    }
    .card-deck{
        margin: 0;
    }
    .heading_products strong{
        margin-top: 0;
    }
    
}

.store-btn{
    background-color: var(--color-blue);
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 8px;
}

