.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
.h-custom {
height: calc(100% - 73px);
}
@media (max-width: 450px) {
.h-custom {
height: 100%;
}
}

.btn-custome-login{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    background-color: var(--color-blue);
    color: white;
}

.btn-custome-login:hover{
    border: 2px solid var(--color-blue);
    color: black;
}

.btn-custome-fab{
    background-color: var(--color-blue);
    color: white;    
}

.btn-custome-fab:hover{
    border: 2px solid var(--color-blue);
}



