.forgotpassword__customer{
    background: linear-gradient(to top, #ddffb1, #e3f3ce);
    height: 100vh;
}

.forgotpassword_customer_main{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 1rem;

}

.forgotpassword_details_customer{
    /* border: 2px solid rgb(93, 92, 92); */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 40%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.forgotpasswordheading{
    display: flex;
    gap:20px;    
}

.emailactive{
    text-decoration: underline;
}

.forgotpasswordheading h5{
    cursor: pointer;
}

.forgotpasswordemail_customer{
    display: flex;
    flex-direction: column;   
}

.forgotpasswordotp_customer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
}

.forgotpasswordotp_customer input{
    /* height: 2rem; */
}

.forgotpassword_details_customer input{
    border: 1px solid rgb(209, 205, 205);
    margin: 0.5rem 0;
}

.forgotpassword_otpbtn{
    background-color: var(--color-blue);
    color: white;
    padding: 9px 0rem;
    border-radius: 5px;
    width: 7rem;
    cursor: pointer;
}

.forgotpassword_update_btn{
    margin-top: 2rem;
}

.forgotconfirmpassword_customer{
    position: relative;
    display: flex;
    align-items: center;
}

.toggle-forgotpassword-btn{
    position: absolute;
    top:30%;
    right: 10px;
}

@media screen and (max-width:768px) {
    .forgotpassword_details_customer{
        width: 100%;
    }
}

@media screen and (max-width:400px) {
    .forgotpassword_details_customer{
        padding: 20px;
    }
    .forgotpasswordotp_customer{
        flex-direction: column;
        align-items: flex-start;
    }
    .forgotpassword_otpbtn{
    }
}
