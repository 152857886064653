/* Overall container */
.order-container_customer {
    margin: 5rem 2rem;
    font-family: Arial, sans-serif;
  }
  
  /* Breadcrumbs */
  .breadcrumbs_customer {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  /* Summary Section */
  .summary-section_customer {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .summary-section_customer > div {
    flex: 1;
    min-width: 280px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
  }
  
  .summary-section_customer h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .summary-section_customer p {
    font-size: 14px;
    color: #555;
    padding-top: 20px;
  }
  
  .rewards_customer p {
    padding-top: 10px;
    color: #555;
  }
  
  .reward-highlight_customer {
    color: #ff9800;
    font-weight: bold;
  }
  
  /* Padding for Download Invoice Section */
  .more-actions_customer p {
    padding: 10px 0;
  }
  
  .download-btn_customer {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color:#28a745;
    /* background-color: #28a745; */
    border: none;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 100px;
    cursor: pointer;
    margin-top: 20px; /* Adjust spacing */
  }
  
  .download-btn_customer:hover {
    background-color: #218838;
  }
  
  /* Product Section */
  .product-section_customer {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
  }
  
  .order-section_customer{
    display: flex
    ;
        flex-wrap: wrap;
        border: 1px solid #ddd;
        padding: 15px;
        border-radius: 5px;
        align-items: center;
        justify-content:flex-start;
        gap:2rem;        
  }

  .product-image_customer img {
    width: 100px;
    /* height: 150px; */
    object-fit: cover;
    border: 1px solid #ddd;
  }
  
  .product-details_customer h4 {
    font-size: 16px;
    color: #333;
    /* margin-left: 120px; */
    /* margin-top: -120px; */
  }
  
  .price_customer  {
    font-size: 14px;
    color: #28a745;
    /* margin-left: 120px; */
    /* margin-top: -200px; */
   
    font-weight: bold;
  }

  /* Timeline Section */
  .order-timeline_customer {
    display: flex;
    align-items: center;
    /* position: relative; */
    justify-content: center;

/* margin-top: -180px; */

    padding: 20px 0;
    /* margin-left: 300px; */
  }
  
  .timeline-step_customer {
    text-align: center;
    position: relative;
    
  }
  
  .timeline-step_customer p {
    font-size: 12px;
    margin: 0;
    color: #555;
  }
  
  .step-dot_customer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ccc;
    margin: 0 auto 5px;
  }
  
  .step-dot_customer.active{
    background: #28a745;
  }
  
  /* Green Line Between Status and Date */
  .timeline-step_customer::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 50%;
    /* margin-left: -10px; */
    /* width: 100%; */
    width: 151px;
    height: 2px;
    margin-top: 25px;
    /* margin-left: 0px; */
    background: grey;
    z-index: -1;
  }
  
  .timeline-step_customer:first-child::after {
    left: 50%;
  }
  
  .timeline-step_customer:last-child::after {
    width: 50%;
  }
  
  /* Scrollable Timeline */
  .scroll-timeline_customer {
    display: flex;
    overflow-x: auto;
    align-items: center;
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  
  .scroll-timeline_customer::-webkit-scrollbar {
    height: 6px;
  }
  
  .scroll-timeline_customer::-webkit-scrollbar-thumb {
    background: #28a745;
    border-radius: 10px;
  }
  
  .timeline-pointer_customer {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #28a745;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .timeline-pointer_customer.active {
    box-shadow: 0 0 8px rgba(40, 167, 69, 0.8);
  }
  
  .status-text_customer {
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  .step-text_customer{
    padding-bottom: 30px;
    /* padding-left: 30px; */
    font-size: 14px;
    /* margin-left: 40px; */
  }
  .step-date_customer{
    font-size: 12px;
    /* margin-left: 35px; */
  }
   .dots-container_customer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    margin-left: 95px;
    top: 24px; /* Adjust this based on where you want the dots to be positioned */
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Adjust width to fit within the step container */
    /* margin-right: 100px; */
  }
  
  .dots-container_customer .dot1_customer {
    width: 15px;
    height: 15px;
    /* margin-left: -44px; */
    border:1px solid grey;
    border-radius: 50%;
   background:white;
    /* margin: 0 5px; */
    
   
  }
  .dots-container_customer .dot2_customer {
    width: 15px;
    height: 15px;
    /* margin-left: -23px; */
    border:1px solid grey;
    border-radius: 50%;
    /* background-color: #28a745; Green color */
    /* margin: 0 5px; */
    background: white;
    
   
}

.product-details__customer{
  flex-direction: column;
}
.dots-container_customer .dot3_customer {
    width: 15px;
    height: 15px;
    /* margin-left: -15px; */
    border:1px solid grey;
    border-radius: 50%;
    background: white;
    
   
  }
  .dots-container_customer .dot4_customer {
    width: 15px;
    height: 15px;
   
    border:1px solid grey;
    border-radius: 50%;
    background: white;
    margin-left: 0px;
    
   
  }

  .order-whole-sectionwithrating{
    border: 2px solid rgb(228, 219, 219);
    border-radius: 8px;
    padding: 1.5rem;
  }
  

  
  /* ################################################## */

  @media screen and (max-width:768px) {
    .product-section_customer{
      
    }
    
  }