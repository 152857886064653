.overflow-y-auto {
    overflow-y: auto;
}

.flex-col {
    flex-direction: column;
}
.flex {
    display: flex;
}

.py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}
.py-7 a {
    color: inherit;
    text-decoration: inherit;
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

/* Add this CSS to your InContact.css file */
input:focus {
    outline: none;
  }
  

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243,244,246,var(--tw-bg-opacity));
}
.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243,244,246,var(--tw-border-opacity));
}
.border-2 {
    border-width: 2px;
}
.rounded {
    border-radius: 4px;
}
.gap-x-2 {
    -moz-column-gap: .5rem;
    column-gap: 0.5rem;
}

.px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
}

.max-w-lg {
    max-width: 32rem;
}

.w-1x, .w-full {
    width: 100%;
}
.m-auto {
    margin: auto;
}
.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17,24,39,var(--tw-text-opacity));
}

.font-semibold {
    font-weight: 600;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75,85,99,var(--tw-text-opacity));
}

.gap-y-10 {
    row-gap: 2.5rem;
}
.grid {
    display: grid;
}
.mt-10 {
    margin-top: 2.5rem;
}
.gap-y-6 {
    row-gap: 1.5rem;
}

.grid {
    display: grid;
}
fieldset, ol, ul {
    margin: 0;
    padding: 0;
}
.gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
}

.grid-cols-2 {
    grid-template-columns: repeat(2,minmax(0,1fr));
}
.grid {
    display: grid;
}
.gap-2 {
    gap: 0.5rem;
}

.flex-col {
    flex-direction: column;
}
.flex {
    display: flex;
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75,85,99,var(--tw-text-opacity));
}

.font-medium {
    font-weight: 500;
}
.text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220,38,38,var(--tw-text-opacity));
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243,244,246,var(--tw-bg-opacity));
}
.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243,244,246,var(--tw-border-opacity));
}
.border-2 {
    border-width: 2px;
}
.rounded {
    border-radius: 4px;
}
.gap-x-2 {
    -moz-column-gap: .5rem;
    column-gap: 0.5rem;
}
.items-center {
    align-items: center;
}
.flex-grow {
    flex-grow: 1;
}

.h-10 {
    height: 2.5rem;
}
.m-0 {
    margin: 0;
}
form .select, form input, form select, form textarea {
    margin-bottom: 0.5rem;
}
.p-0 {
    padding: 0;
}

.bg-transparent {
    background-color: transparent;
}
.border-none {
    border-style: none;
}
.appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input, input, select, textarea {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    border-radius: 4px;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgba(243,244,246,var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(243,244,246,var(--tw-bg-opacity));
    padding: 0.5rem;
}
.gap-2 {
    gap: 0.5rem;
}

.flex-col {
    flex-direction: column;
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75,85,99,var(--tw-text-opacity));
}

.font-medium {
    font-weight: 500;
}
.text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220,38,38,var(--tw-text-opacity));
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.p-6 {
    padding: 1.5rem;
}
element.style {
    background-image: url(https://formspree.io/static/js/../3217165….svg);
    background-position: -140px -200px;
    background-size: 697px 559px;
    background-repeat: no-repeat;
}
.text-white {
    --tw-text-opacity: 1;
    color: rgba(255,255,255,var(--tw-text-opacity));
}
.py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.bg-secondary {
    --tw-bg-opacity: 1;
    background-color: rgba(14,35,45,var(--tw-bg-opacity));
}
.rounded-lg {
    border-radius: 20px;
}
.place-content-center {
    place-content: center;
}
.grid {
    display: grid;
}
.relative {
    position: relative;
}

element.style {
}
.gap-y-12 {
    row-gap: 3rem;
}
.max-w-lg {
    max-width: 32rem;
}
.w-1x, .w-full {
    width: 100%;
}
.grid {
    display: grid;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
ol, ul {
    list-style: none;
}
fieldset, ol, ul {
    margin: 0;
    padding: 0;
}
.gap-x-6 {
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
}

.items-center {
    align-items: center;
}
.flex-shrink-0 {
    flex-shrink: 0;
}

.inline-flex {
    display: inline-flex;
}
.flex-grow {
    flex-grow: 1;
}
.leading-8 {
    line-height: 2rem;
}

.font-bold {
    font-weight: 700;
}
.text-xl {
    font-size: 1.25rem;
}
.mt-2 {
    margin-top: 0.5rem;
}

.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209,213,219,var(--tw-border-opacity));
}

.border {
    border-width: 1px;
}
.rounded {
    border-radius: 4px;
}
.cursor-pointer {
    cursor: pointer;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.w-4 {
    width: 1rem;
}
.h-4 {
    height: 1rem;
}


.bg-secondary {
    --tw-bg-opacity: 1;
    background-color: rgba(14,35,45,var(--tw-bg-opacity));
}

.flex-col {
    flex-direction: column;
}
.min-h-screen {
    min-height: 100vh;
}


@media screen and (max-width:990px) {
    
}

@media screen and (max-width:650px){
 .incontact_parent aside{
    display: none;
}
.incontact_parent section main{
    
    width: 100vw;
}
}