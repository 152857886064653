

.footer-container .footer-top {
    display: grid;
    grid-template-columns:1.5fr 1fr 1fr;
    padding-left: 0;
    padding-right: 16px;
    padding-top: 48px;
    padding-bottom: 48px;
    justify-content: space-between;
    background-color: var(--light-grey);
    color: var(--white);
    font-family: 'Poppins', sans-serif;
}

.footer-container .footer-top .mainmf-c-1 {
    flex: 2;
}

#alright{
    display: flex;
    flex-direction: column;
    margin-left: 90px;
    margin-top: 104px;
}

.f-b-left{
    display: flex; flex-direction: column; row-gap: 0px; margin-top: 104px;
}

.ipart2{
    display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 12px;
}

.footer-container .footer-bottom .f-b-right {
    display: flex;
    flex: 1;
    flex-direction: row;
    column-gap: 12px;
    justify-content: right;
}

.footer-container .footer-top .mainmf-c-1 img{
    margin-top:1rem;
    opacity: 1;
    border-width: 2px;
	border-style: solid;
	border-color: #00000000;
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
    padding: 12px 24px;
}


.footer-container .footer-top .mf-c-1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    column-gap: 48px;
}

.footer-container .footer-top #c-us {
    flex: 1;
    margin-right: 0vw;
}

.footer-container .footer-top a {
    text-decoration: none;
}

.footer-container .footer-top h2 {
    width: 184px;
    height: 48px;
    color: var(--mid-black);
    border: 1px solid var(--mid-black);
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
    padding-top: 12px;
    font-family: 'Poppins',sans-serif;
    font-weight: 300;
    letter-spacing: 2.2;
    text-decoration: none;

}

.footer-container .footer-top h2:hover {
    cursor: pointer;
    transition: 0.4s;
    color: var(--purple);
    border: 1px solid var(--purple);

}

.footer-container .footer-top h3 {
    padding-top: 3rem;
    color: var(--mid-black);
    font-size: 13px;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 1.1px;
    text-decoration: none;
    margin-top: 3vh;
    margin-left: 90px;
    opacity: 1;
}

.footer-container .footer-top h4 {
    cursor: pointer;
    transition: 0.3s;
    padding-top: 2vh;
    padding-bottom: 1vh;
    width: 155px;
    text-align: start;
    color: var(--mid-black);
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 2.2;
    text-decoration: none;

}

.footer-container .footer-top h4:hover {
    transition: 0.3s;
    color: var(--mid-black);
    font-weight: 400;


}

.footer-container .footer-line {
    height: 2px;
    margin-left: 7vw;
    margin-right: 7vw;
    margin-top: -2px;
    background-color: var(--dark-blue);
}




.footer-container .footer-mid {
    display: flex;
    flex-direction: row;
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    justify-content: space-around;


    background-color: var(--purple);
    color: var(--mid-black);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: normal;
}

.footer-container .footer-mid .mainmf-c-1 {
    flex: 2;
    margin-right: 9vw;
}

.footer-container .footer-mid .mf-c-1 {
    flex: 1;
    margin-right: 5vw;
}

.footer-container .footer-mid a {
    text-decoration: none;
}

.footer-container .footer-mid h1 {
    margin-top: 2vh;
    color: var(--white);
    font-size: 14px;
    font-family: 'Poppins',sans-serif;
    font-weight: 500;
    letter-spacing: 2.2;
    text-decoration: none;
    opacity: 1;

}

.footer-container .footer-mid h2 {
    margin-top: 2vh;
    padding: 1vh;
    color: var(--light-purple);
    border: 1px solid var(--white);
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
    font-family: 'Poppins',sans-serif;
    font-weight: 500;
    letter-spacing: 2.2;
    text-decoration: none;

}

.footer-container .footer-mid h2:hover {
    cursor: pointer;
    transition: 0.4s;
    color: var(--white);

}

.footer-container .footer-mid h3 {
    padding-top: 2vh;
    color: var(--white);
    font-size: 12px;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 2.2;
    text-decoration: none;
    margin-top: 3vh;

}

.footer-container .footer-mid h4 {
    cursor: pointer;
    transition: 0.3s;
    padding-top: 2vh;
    padding-bottom: 1vh;
    color: var(--white);
    font-size: 12px;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 2.2;
    text-decoration: none;

}

.footer-container .footer-mid h4:hover {
    transition: 0.3s;
    color: var(--white);
    font-weight: 400;


}

.footer-container .footer-bottom {
    display: flex;
    flex-direction: row;
    padding-left: 90px;
    padding-right: 7vw;
    justify-content: space-around;
    background-color: var(--mid-grey);
    color: var(--white);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.footer-container .footer-bottom .f-b-left2 {
    flex: 1;
    display: flex;
    align-items: center;
}

.footer-container .footer-bottom .f-b-left2 h2 {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1;
    font-family: 'poppins';
    color: black;
}

.footer-container .footer-bottom .f-b-right .mybox {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: transparent;
    transition: 0.4s;
}

.footer-container .footer-bottom .f-b-right {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: right;
}

.footer-container .footer-bottom .f-b-right .mybox:hover {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: white;
    margin: 0
    ;
    background-color: rgb(156, 156, 211);
    transition: 0.4s;
}

.footer-container .footer-bottom .f-b-right .mybox svg {
     filter: invert(0%);
     transition: 0.4s;
     color: var(--mid-black);
 }

 /* .footer-container .footer-bottom .f-b-right .mybox svg:hover {
    filter: invert(2%);
     transition: 0.4s;
 } */

 
 
 

 @media screen and (max-width: 750px) {
    
    
.footer-container .footer-top {
    display: grid;
    grid-template-columns: 1fr ;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    


    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: normal;
}


.footer-container .footer-top h3 {
    padding-top: 2vh;
    font-size: 13px;
    font-family:'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 1.1px;
    text-decoration: none;
    margin-top: 3vh;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    opacity: 1;
}


.footer-container .footer-mid {
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    justify-content: space-around;


    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: normal;
}

.footer-container .footer-mid .mainmf-c-1 {
    flex: 2;
    margin-right: 3vw;
}


.footer-container .footer-mid .mf-c-1 {
    flex: 1;
    margin-right: 3vw;
}

.footer-container .footer-mid a {
    text-decoration: none;
}

.footer-container .footer-mid h1 {
    margin-top: 1vh;
    font-size: 12px;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 2.2;
    text-decoration: none;

}

.footer-container .footer-mid h2 {
    margin-top: 2vh;
    padding: 1vh;
    text-align: center;
    border-radius: 50px;
    font-size: 12px;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 2.2;
    text-decoration: none;

}

.footer-container .footer-mid h2:hover {
    cursor: pointer;
    transition: 0.4s;

}

.footer-container .footer-mid h3 {
    padding-top: 2vh;
    font-size: 12px;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 2.2;
    text-decoration: none;
    margin-top: 3vh;

}

.footer-container .footer-mid h4 {
    cursor: pointer;
    transition: 0.3s;
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 12px;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 2.2;
    text-decoration: none;

}

.footer-container .footer-mid h4:hover {
    transition: 0.3s;
    color: var(--white);
    font-weight: 400;


}

#alright {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 50px;
    justify-content: start;
    justify-items: start;
    justify-self: start;
    margin-left: 12px;
    margin-right: 12px;
}
.footer-container .footer-top h4 {
    margin-bottom: 12px;
}

.f-b-left{
    display: flex; flex-direction: column; row-gap: 0px; margin-top: 24px;
}

.ipart2{
    display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 12px;
                margin-left: 56px;
}


.footer-container .footer-bottom {
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    padding-left: 3vw;
    padding-right: 3vw;
    align-items: center;
    justify-content: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.footer-container .footer-bottom .f-b-left2 {
    flex: 1;
    display: flex;
    align-items: center;
}

.footer-container .footer-bottom .f-b-left2 h2 {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1;
    font-family: 'poppins';
}

.footer-container .footer-bottom .f-b-right .mybox {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: transparent;
    transition: 0.4s;
}

.footer-container .footer-bottom .f-b-right {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: right;
}

.footer-container .footer-bottom .f-b-right .mybox:hover {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: 0.4s;
}

.footer-container .footer-bottom .f-b-right .mybox svg {
     filter: invert(0%);
     transition: 0.4s;
 }

 .footer-container .footer-bottom .f-b-right .mybox svg:hover {
    filter: invert(10%);
     transition: 0.4s;
 }
 }