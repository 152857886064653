.manage_address_main_div_customer{
    display: flex;
    flex-direction: row;
   
    
}

.manage_new_address_customer{
    margin-top: 130px;
    margin-left: 40px;
   
}
.manage_new_address_customer p{
  border: 1px solid #ddd;
   margin-top: 30px;
   width: 700px;
   padding: 10px;
   color: #28A715;
   font-weight: 540;
   border-radius: 10px;
}
.manage_new_address_customer p:hover{
    cursor: pointer;
}
.manage_new_address_customer h4{
    font-weight: normal;
}
.home_address_customer{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-left: 40px;
    border: 1px solid #ddd;
    justify-content: space-between;
    padding: 20px;
}
.home_name_div_customer {
    gap: 1.5rem;
    margin-left: 20px;
}
.home_head_customer{
    background: #D9D9D9;
    opacity: 35%;
    font-size: 20px;
    color: black;
    padding: 10px;
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-weight: 540;
    
}
.name_head_customer{
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
}
.edit_address_customer{
    color:#28A715;
    font-size: 16px;
    font-weight: 540;
}
.del_address_customer{
color:red;
font-size: 16px;
    font-weight: 540;
    margin-top: 20px;
}
.address_content_div_customer{
    margin-top: 40px;
    font-size: 16px;
}
.address_phn_number_customer{
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    
    
}
.address_content_div_customer {
    margin-top: 40px;
    font-size: 16px;
    width: 450px; /* Full width to occupy available space */
    word-wrap: break-word; /* Break words if too long */
    white-space: normal; /* Allow the text to wrap to the next line */
    overflow-wrap: break-word; /* Additional support for text wrapping */
}
/* ############## new addresss form ##########*/
.address_form_customer{
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 20px;
    /* margin-left: 40px; */
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .form_row_customer {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .input_field_customer, .address_input_customer {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .address_input_customer {
    height: 60px;
    resize: none;
  }
  
  .form_row_customer label {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
  
  .save_btn_customer {
    padding: 10px 20px;
    background-color: #33C71C;
    opacity: 67%;

    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cancel_text_customer {
    padding: 10px;
    color: #33C71C;
    cursor: pointer;
    font-size: 16px;
    
  }
  

  @media screen and (max-width:768px) {
    .manage_address_main_div_customer{
        flex-direction: column;
    }

    .manage_new_address_customer {
      width: 100%;
      /* text-align: center; */
      margin-left: 0;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    .manage_new_address_customer p{
        width: 80%;
        
    }
    .home_name_div_customer{
      margin: 0;
    }
    .home_address_customer {
      flex-direction: column;
      margin: 1rem;
    }
    .address_content_div_customer{
      width: 100%;
    }
    .address_form_customer{
      margin: 20px;
    }
  }