.singlecart-content_customer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
        flex-direction: column;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 10px;
        margin: 2rem auto;
        width: 60vw;
}

.singleproduct-left-discount_customer{
    float: left;
}

.singleproduct_left_side_customer{
    /* margin: auto; */
    display: flex;
    justify-content: space-around;
    gap:2rem;
}

.buysingleitem_customer{
    display: flex;
}

.buysingleItem_customer{
    margin-top: 5rem;
}

.leftbuysingleitem_side_customer{
    height: 35vh;
    border: 2px solid rgb(128, 126, 126);
    overflow: scroll;
}

.modalbuysingleitem_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.paymentbuysingleitem_modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 50%;
}

.buysingleItem__buttons{
    float: left;   
}

.busysingleitem_orderbtn{
 background-color: var(--color-blue);
 color: white;
 padding: 10px;
 border-radius: 8px;
 margin-right: 1rem;
}

.total_discount_msg_customer {
    transform: translateY(-10px); /* Start position */
    animation: fadeIn 0.5s ease-in-out forwards; /* Apply animation */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-10px); /* Move upwards */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Final position */
    }
}

.animate-fade-in {
    animation: fadeIn 0.5s ease-in-out forwards;
}


@media screen and (max-width:768px) {
    .singleproduct_left_side_customer{
        flex-direction: column;
    }
    .singlecart-content_customer{
        width: 90vw;
        margin: 1rem;
    }
    
.paymentbuysingleitem_modal{
    width: 90%;
}
}