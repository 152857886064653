#container{
    background-image: url('../Images/home.png');
    height: 50vw;
    /* background-color: var(--color-blue); */
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}


#home{
    color: white;
    text-align: left;
    top:40%;
    left:5%;
    position:absolute;
    font-weight: 500;
    gap:1rem;
}

.home__heading{
    text-align: left;
}

.home__heading p{
    font-style: italic;
    font-size: 25px;
    /* margin-top: -15px; */
  
}

.mleft p{
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}


.home__products{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    background-color: white;
    color: black;
    margin-top: 3rem;
    border-radius: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;    
}


.home__products .mleft{
        display: flex;
        flex-direction: row;
}


.home__products .mright{
}

.home__products .mleft .img__wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
}

.home__products img{
    height: 2.5rem;
    width: 2.5rem;  
}

.img__wrapper img{
    transform: scale(1);
    cursor: pointer;
}
.img__wrapper img:hover{
    transform: scale(1.3);
    transition: 0.3s ease-in-out;
}


.home__products p{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 2rem;
    width: auto;
    margin-top: 1rem;
}

.home__products button{
    border: none;
    background-color: var(--color-primary);
    color: white;
    height: 3rem;
    border-radius: 0px 3rem 3rem 0px;
    width: 10rem;
    margin-left: 4rem;
    font-weight: bold;
    
}



.home__iot{
    bottom: 0;
    position: absolute;
    left:3%;
}

.home__iot img{
    height: 8rem;
    position: absolute;
    bottom: 0;
    margin-bottom: -0.8rem;
}


.below_div{
    background-color: var(--color-primary);
    height: 0.8rem;
    margin-bottom: -0.7rem;
    z-index: 2;
}


@media screen and (max-width:1000px) {
    #container{
        height: 100vh;
    }
    .home__heading p{
        font-size: 20px;
    }
}

@media screen and (max-width:990px) {
    #container{
        height: 50vh;
         background-color: var(--color-blue);
    }
}


@media (min-width:636px) and (max-width:1000px){
    .home__iot img{
        display:none;        
    }
}

@media screen and (max-width:650px) {
    #container{
        height: 100vh;
    }
    .home__products{
        display: flex;
        flex-direction: column;
        background-color: transparent;
        /* width: 80vw; */
        height: 0;
        z-index: 1;
        align-items: center;  
        justify-content: center;         
    }
    .home__heading {
        text-align: center;
        margin: 0;
        padding: 0;
    }
    
    .home__products button{
        margin-top:1rem;
        margin-bottom: 1rem;
        margin-left: 0;
    }
#home{
    top:15%;
    text-align: center;
    left: 0%;
    padding: 8px;
}
.home__products .mleft .img__wrapper{
    margin-left: 0;
}
    .img__wrapper{
        margin:1rem 0;
    }
    .home__products p{
        margin-left: 0;
    }

.home__products .mleft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50%;
}

.home__products{

}

.mleft p
{
color: white;
text-align: center;
font-size: 1.25rem;
margin-bottom: -1px;
}

.home__products .img__wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 0;
}
.home__products button{
    height: 3rem;
    border-radius: 3rem;
    width: 9rem;
    font-weight: 600;
    /* margin-top: 3rem;     */
}

.home__iot img{
    height: 5rem;
    z-index: 0;
    position: absolute;
    bottom: 0;
    margin-bottom: -0.7rem;
    
}
.home__products img{
    height: 2.5rem;
    width: 2.5rem;  
}

}


