.dreamit-counter-area {
    background: url('https://html.ditsolution.net/cleaning/assets/images/counter.png');
    background-color: var(--color-blue);
    padding: 101px;
    color: white;
}


.dreamit-counter-icon {
    margin-right: 20px;
}

.dreamit-counter-icon i {
    color: #279e64;
    font-size: 50px;
    display: inline-block;
    margin-top: 12px;
}


@media screen and (max-width: 991px) {
    .dreamit-single-counter {
        margin-bottom: 30px;
    }
 
}

