.ecom-orderhistory-newdiv{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:20px;
    justify-content: flex-end;
    margin: 1rem 2rem 0 0;
}

.ecom-orderhistory-dropdown{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ecom-orderhistory-dropdown p{
    text-align: center;
}

.ecom-orderhistory-dropdown select{
    /* width: 140px; */
    border-radius: 7px;
    border: 2px solid #C9C7C7;
    outline: none;
}

.ecom-orderhistory-pagebtn{
    display: flex;
    align-items: center;
}

.ecom-orderhistory-pagebtn button{
    width: 2rem;
}


.ecom-orderhistory-address{
    /* border: 2px solid grey; */
}

.ecom-order-history-container{
    /* border: 1px solid #000; */
    display: flex;
}
.ecom-orderhistory-leftside-div{
    width: 50%;
}

.ecom-orderhistory-rightside-div{
    width: 50%;
    flex:1;
    border-left: 1px solid grey;
    padding-left: 2rem;
}


.ecom-orderhistory-order-item {
    display: flex;
    /* justify-content: space-between; */
    gap:2rem;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .ecom-orderhistory-order-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .ecom-orderhistory-action-buttons {
    display: flex;
    gap: 10px;
  }

  .ecom-orderhistory-action-buttons{
 margin-top: 1.5rem;
}

.ecom-orderhistroy-otpbtns{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.ecom-orderhistroy-otpbtns input{
    width: 7rem;
    border-radius: 8px;
    border: 2px solid grey;
}

.updatepaymentdiv{
    margin: 10px 0px;
    width: 90%;
}
  @media screen and (max-width:768px) {
    .ecom-order-history-container{
        flex-direction: column;
    }
    .ecom-orderhistory-leftside-div{
        width: 100%;
    }
    .ecom-orderhistory-rightside-div{
        width: 100%;
        padding-top: 2rem;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid grey;
    }
    .ecom-orderhistory-action-buttons{
        display: flex;
        flex-direction: column;
    }
    .ecom-orderhistory-order-item{
        flex-direction: column;
    }
   
  }

  