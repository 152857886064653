

.ecom-dashboard-main{
  
}


.ecom-dashboard-container{
    margin:1rem 2rem;
}

.ecom-dashboard-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ecom-dashboard-search{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.ecom-dashboard-search-input{
    width: 30rem;
    height: 2rem;
    border-radius: 20px;
    border: 2px solid rgb(211, 211, 211);
}

.ecom-dashboard-search-input:focus{
    outline: none;
    padding-left: 10px;
}

.ecom-dashboard-search-input::placeholder{
    padding-left: 10px;
}

.ecom-dashboard-search-icon{
    position:absolute;
    right:10px;
    pointer-events: none;
    color:rgb(198, 193, 193);
    font-size: 20px;
    font-weight: 700;
}

.ecom-dashboard-user-details{
    display: flex;
    padding: 1rem;
    justify-content: space-around;
    align-items: center;    
    gap: 2rem;
}

.ecom-dashboard-icon{
    color: blue;
    font-size: 20px;
    cursor: pointer;
}

.ecom-dashboard-exit-icon{
    color: red;
    font-size: 20px;
    cursor: pointer;
}


/* dashboard content */
.ecom-dashboard-content{
    margin: 2rem;
}

.ecom-dashboard-date{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 600;
}

.ecom-dashboard-date p{
    padding: 0 20px;
    color: grey;
}

.ecom-dashboard-date p span{
    color: black;
}


.ecom-dashboard-revenues{
    display: flex;
    gap: 2rem;
    align-items: center;
}

.ecom-dashboard-revenue-box{
    background-color:#5067FF;
    width: 12rem;
    height: 8rem;
    border-radius: 12px;
    color: white;
    padding: 10px 12px;
    position:relative;
    font-weight: 600;
}

.ecom-dashboard-top-left{
    position: absolute;
    left: 12px;
}

.ecom-dashboard-bottom-right{
    position: absolute;
    right: 30px;
    bottom: 0;
    font-size: 2rem;
}


.ecom-dashboard-analytics{
    display: flex;
    margin-top: 1rem;
    gap:4rem;
}

.ecom-dashboard-analytic-chart{
    height: 20rem;
    width: 40rem;
    background-color: white;
    border-radius: 10px;
    border: 2px solid rgb(202, 199, 199);
}

.ecom-dashboard-analytics-growth{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}

.ecom-analytics{
    font-size: 1.2rem;
    font-weight: 600;
}

.ecom-analytics-text{
    font-size: 1.2rem;
    font-weight: 600;
}

.ecom-analytics-growth{
    width: 12rem;
    height: 8rem;
    background-color: white;
    border-radius: 10px;
}

.ecom-analytics-growth {
padding: 2px 12px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position:relative;
}

.ecom-analytics-3text{
    position: absolute;
    text-align: center;
}

.ecom-dashboard-percent-text{
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--color-primary);
    text-align: center;
}



.ecom-dashboard-total-products{
    margin-top: 1rem;
}

.ecom-dashboard-total-product{
    display: flex;
    gap:2rem;
}

.ecom-dashboard-product-box{
    background-color:#5067FF;
    width: 10rem;
    height: 2rem;
    border-radius: 8px;
    display: flex;
    color: white;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    padding: 2px 8px;
}

.ecom-dashboard-total-product-count{
    font-weight: 600;
    font-size: 1.2rem;
}

.ecom-create-coupondiv{
    /* margin-right: 2rem; */
}
.ecom-create-coupondiv button{
    background-color: var(--color-blue);
    padding: 2px 2rem;
    border-radius: 8px;
    color: white;
    float: right;
}



@media screen and (max-width:1024px) {
    .ecom-dashboard-search-input{
        width: 20rem;
    }
    .ecom-dashboard-header{
        flex-direction: column;
    }
    .ecom-dashboard-content{
        margin: 0;
    }
    .ecom-dashboard-revenues{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .ecom-dashboard-analytics{
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .ecom-dashboard-analytic-chart{
        width: 20rem;
    }
    .ecom-dashboard-total-product{
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width:768px) {
    .ecom-dashboard-search-input{
        width: 15rem;
    }
    .ecom-dashboard-date{
         flex-wrap: wrap;
        margin-bottom: 1rem;
        justify-content: center;
    }
    .ecom-dashboard-analytic-chart{
        width: 15rem;
    }
}




/* coupon class */
.couponclasscreationdiv{
border: 2px solid rgb(207, 204, 204);
border-radius: 8px;
margin: 2rem;
padding: 2rem;
}

.creetecoupon{
    margin-top: 1rem;
    background-color: #5067FF;
    padding:7px 15px;
    border-radius: 8px;
    color: white;
}

.coupongetclassdiv{
    margin:0 2rem;
}


/* Container to make the table responsive */
.coupontable-container {
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
  }
  
  /* Styling for the table */
  .couponresponsive-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    border: 1px solid #ddd;
  }
  
  .couponresponsive-table th,
  .couponresponsive-table td {
    text-align: left;
    padding: 12px;
    border: 1px solid #ddd; /* Add border to cells */
  }
  
  .couponresponsive-table th {
    background-color: #f4f4f4; /* Light gray background for headers */
    font-weight: bold;
  }
  
  /* Zebra striping for table rows */
  .couponresponsive-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Hover effect on table rows */
  .couponresponsive-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Make the table scroll horizontally on smaller screens */
  .coupontable-container {
    overflow-x: auto;
  }
  
  .update-coupon{
    background-color: #5067FF;
    padding: 2px 5px;
    border-radius: 5px;
    color: white;
  }

  /* Modal Styles */
.couponmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .couponmodal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .couponmodal h3 {
    margin-bottom: 15px;
  }
  
  .couponform-group {
    margin-bottom: 10px;
  }
  
  .couponform-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .couponform-group input,
  .couponform-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .couponmodal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .couponmodal-actions button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .couponmodal-actions button:first-child {
    background-color: #28a745;
    color: #fff;
  }
  
  .couponmodal-actions button:last-child {
    background-color: #dc3545;
    color: #fff;
  }

  /* Responsive behavior for small screens */
  @media (max-width: 600px) {
    .couponresponsive-table th,
    .couponresponsive-table td {
      font-size: 12px;
      padding: 8px;
    }
  }
  