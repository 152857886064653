.ecom-allusers-container{
    margin: 2rem;
}

.ecom-allusers-headers{
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.ecom-allusers-left-person-heading{
    display: flex;
    width: 40vw;
    gap:3rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.ecom-allusers-header-line{
    border: 2px solid grey;
    height: 20px;
    margin: 0 1rem;
}

.ecom-allusers-left-person-heading p{
    font-size: 1.01rem;
    color: #A1A1A1;
    font-weight: 700;
    cursor: pointer;

}


.ecom-allusers-left-person-heading p.ecom-user-active,
.ecom-allusers-right-person-approving p.ecom-user-active
{
    text-decoration: underline;
    color: black;
}

.ecom-allusers-right-person-approving{
    display: flex;
    gap:3rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.ecom-allusers-right-person-approving p{
    font-size: 1.01rem;
    color: #A1A1A1;
    font-weight: 700;
    cursor: pointer;
}



.ecom-allusers-content {
    padding: 20px;
    background: #f9f9f9;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th, .user-table td {
    /* border: 1px solid #ddd; */
    padding: 8px;
    text-align: left;
  }
  
  .user-table th {
    color: black;
  }
  
  .table-container {
    overflow-x: auto;
    width: 100%;
  }

  .ecom-allusers-left-details{
margin-top:3rem;
  }

  .ecom-alluser-view-details{
    background-color: #5067FF;
    color: white;
    border-radius: 10px;
    padding:6px 30px;
    cursor: pointer;
    font-family: 'Poppins';
    border: none;
  }

  .ecom-alluser-view-details:active {
    color: #5067FF;
    background-color: white;
    transform: scale(0.95); 
  }

.ecom-alluser-approve{
  background-color: #50BA4E;
  color: white;
    border-radius: 10px;
    padding:6px 30px;
    cursor: pointer;
    font-family: 'Poppins';
    border: none;
}


@media screen and (max-width:1024px) {
    .ecom-allusers-left-person-heading{
        width: 100%;
    }
    .ecom-allusers-headers{
        flex-wrap: wrap;
    }
    .ecom-allusers-left-person-heading{
        gap: 20px;
    }
    .ecom-allusers-right-person-approving{
        gap:20px;
    }
}

@media screen and (max-width:768px){
    .ecom-allusers-header-line{
        display: none;
    }
    .ecom-allusers-right-person-approving{
        margin-top: 1rem;
        gap:10px;
    }
    .ecom-allusers-left-person-heading{
        gap: 10px;
    }
 
    .ecom-allusers-headers {
        flex-direction: column;
        align-items: flex-start;
      }
    
      .user-table th, .user-table td {
        padding: 10px;
      }


       /* Make each row a flexbox container for stacking */
  .user-table, .user-table thead, .user-table tbody, .user-table th, .user-table td, .user-table tr {
    display: flex;
    flex-wrap: wrap;
  }



  /* Make the table rows stack vertically */
  .user-table tr {
    margin-bottom: 15px;
    border-bottom: 2px solid #cccaca;
  }

  /* Each cell will behave like a row */
  .user-table td {
    position: relative;
    padding-left: 50%;
    text-align: left;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    margin-bottom: 10px;
    /* box-shadow: 0px 1px 2px rgba(0,0,0,0.1); */
  }

  /* Add a "label" before each cell's content */
  .user-table td::before {
    content: attr(data-label); /* Use the data-label attribute for each cell */
    position: absolute;
    left: 10px;
    width: 45%;
    font-weight: bold;
    white-space: nowrap;
    font-size: 14px;
    color: #333;
  }
}


