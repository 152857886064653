nav{
    height: auto;
    position: relative;
    left:0;
    top:0;
    z-index: 10;
    width: 100%;
    background: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}



.nav-container{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;

}


nav.scrolled {
  margin-top: 0;
  /* Add any additional styling you need for the scrolled state */
}


.nav__logo img{
   /* height: 5rem;
    width: auto; */
    display: none;
}
nav ul{
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
    /* margin-left: 4rem;*/
    /* margin-top: 1.4rem; */
}

.default-link {
  color: inherit; /* Use the default color */
  text-decoration: inherit; /* Use the default text decoration (e.g., underline) */
  /* Add any other default styles as needed */
}

.dropdown-submenu .dropdown-menu {
  top: 100%;
  left: 0;
  right: initial;
}

.dropdown-submenu:hover .dropdown-menu {
  display: block;
}


#google_ele{
  margin-left: 20vw;
}
.dropdown-menu li {
  position: relative;
  }
  .dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
  }
  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  }
  
  .dropdown-hover:hover>.dropdown-menu {
  display: inline-block;
  }
  
  .dropdown-hover>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
  }
nav ul li a{
    color: black;
    text-decoration: none;
    /* margin: 0 0.2rem; */
    justify-content: center;
    font-weight: 2rem;
    padding: 0.5vw;
    font-weight: 600;
    /* height: 30rem; */
    transition: height 0.3s;
}

.loginbtn
button{
    border-radius: 5px;
    width: 6rem;
    font-weight: 600;
    height: 2.4rem;
  background-color: var(--color-blue);
  color: white;
}


/* .nav__ul{
    margin-left: 15rem;
} */


nav ul li a:hover{
background: white;
 /* margin-top: 2rem; */
   text-decoration: none;
   border-radius: 6px;
   /* margin-bottom: 2rem; */
}
/* Add this style to hide the logo when scrolled down */
.hide-logo .navlogo {
  /* display: none; */
  opacity: 0;
  margin-top: -80px;
  padding:8px;
  transition: 0.4s ease;
}

/* Additional styles for your components... */


.burger-menu {
  display: none; /* Initially hide the burger menu on larger screens */
  cursor: pointer;
}

.burger-menu .bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 5px auto;
  transition: 0.4s;
  font-family: 'Poppins',"Sans-serif";
}

.active .burger-menu .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.active .burger-menu .bar:nth-child(2) {
  opacity: 0;
}

.active .burger-menu .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}
/* 
@media screen and (max-width:1400px) {
  nav{
    display: flex;
    justify-content: space-between;
    position: fixed;
  }
  .nav__logo img{
    height: 4.5rem;
     width: auto;
     display: block;
     
 }


 #google_ele{
  margin-left: 0;
}
} */

/* Add this to your existing CSS file or style section */
.navbar-collapse {
  position: relative;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

@media (max-width: 767px) {
  .dropdown-submenu:hover .dropdown-menu {
    display: block;
  }
  #google_ele{
    display: none;
  }
}

@media screen and (max-width:990px) {
  .burger-menu {
    margin-right: 1.3rem;
    float: right;
    display: block; /* Show the burger menu on smaller screens */
  }

  .nav__container .active {
    display: flex; /* Show menu items when active class is added */
  }

  nav{
    display: flex;
    justify-content: space-between;
    position: fixed;
  }
  
  .nav__logo img{
    height: 4.5rem;
     width: auto;
     display: block;
     
 }
 #google_ele{
  margin-left: 0;
}
  
}



@media (max-width: 768px) {
    .nav__container {
      display: none; /* Hide the menu items by default on small screens */
      flex-direction: column; /* Stack menu items vertically */
      position: absolute;
      top: 100%; /* Position dropdown menu below the nav bar */
      left: 0;
      width: 100%;
      background: var(--color-primary);
    }
  
    .nav__container.active {
      display: flex; /* Show menu items when active class is added */
    }
  
    .nav__container ul {
      width: 100%;
      padding: 1rem;
      flex-direction: column;
    }
  
    .nav__container ul li {
      margin: 0.5rem 0;
    }
/*   
    .nav__container ul li a {
      color: white;
      font-size: 1.2rem;
    } */
  
    .nav__container ul li a:hover {
      background: transparent;
    }
    .burger-menu {
      margin-right: 1.2rem;
      display: block; /* Show the burger menu on smaller screens */
    }
  
    .nav__container .active {
      display: flex; /* Show menu items when active class is added */
    }

    nav ul li a{
      text-decoration: none;
      /* margin: 0 0.2rem; */
      justify-content: center;
      /* font-weight: 1rem; */
      /* padding: 0; */
      font-weight: 600;
      /* height: 30rem; */
      transition: height 0.3s;
  }
  }

  @media (max-width: 1500px) {
    nav ul li a{
      padding: 0;
      margin: 5px;
  }
  nav{
    margin-top: 0;
    display: flex;
    position: fixed;
    justify-content: space-between;
  }
  
  }


  @media (max-width: 1040px) {
    .nav__container {
      display: none; /* Hide the menu items by default on small screens */
      flex-direction: column; /* Stack menu items vertically */
      position: absolute;
      top: 100%; /* Position dropdown menu below the nav bar */
      left: 0;
      width: 100%;
      background: var(--color-primary);
    }
    .nav__logo img{
      height: 4.5rem;
       width: auto;
       display: block;
       
   }
    .nav__container.active {
      display: flex; /* Show menu items when active class is added */
    }
  
    .nav__container ul {
      width: 100%;
      padding: 1rem;
      flex-direction: column;
    }
  
    .nav__container ul li {
      margin: 0.5rem 0;
    }

    .nav__container ul li a:hover {
      background: transparent;
    }
    .burger-menu {
      margin-right: 1.2rem;
      float:right;
      display: block; /* Show the burger menu on smaller screens */
    }

    #google_ele{
      margin-left: 0;
    }
  
    .nav__container .active {
      display: flex; /* Show menu items when active class is added */
    }

    nav ul li a{

      text-decoration: none;
      justify-content: center;
      padding: 0;
      font-weight: 600;
      transition: height 0.3s;
  }
  }

  