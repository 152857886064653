/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .cart-page_customer {
    padding: 20px;
  }
  
  .header_customer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }
  
  .logo_customer {
    font-size: 24px;
    font-weight: bold;
  }
  
  .cart-icon_customer {
    font-size: 18px;
    cursor: pointer;
  }
  
  .step-navigation_customer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px; /* Set a fixed width */
    margin: 0 auto; /* Center the container */
    margin-top: 80px;
  }
  
  .step-item_customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .step_customer {
    width: 30px; /* Increased size for better visibility */
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px; /* Spacing between step and label */
    cursor: pointer;
  }
  
  .step-label_customer {
    font-size: 14px;
    color: #333;
  }
  
  .line_customer {
    flex-grow: 1; /* Makes the line dynamically expand */
    height: 2px;
    background-color: #ccc;
    margin: 0 -10px; /* Closer spacing so it touches the circles */
    position: relative;
    z-index: -1; /* Positions line behind the circles */
  }
  
  .step-navigation_customer .step_customer:first-child {
    margin-left: 0;
  }
  
  .step-navigation_customer .step_customer:last-child {
    margin-right: 0;
  }








  .completed_customer {
    background-color: var(--color-primary) !important;
    color: white;
  }
  
  .line_customer.completed_customer {
    background-color: var(--color-primary);
  }
  
  .next-button_customer {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .next-button_customer:hover {
    background-color: var(--color-primary);
  }
  
  


  
/* Overall Cart Content */
.cart-content_customer {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd; 
    border-radius: 8px; 
    box-sizing: border-box; 
    padding: 10px;
    margin:10px 0;
  }
  
  /* Product Details Section */
  .product-details_customer {
    display: flex;
    width: 100%;
    justify-content: space-between; /* Space between product info and actions */
  }
  
  /* Left Section: Product Image and Info */
  .product-left_customer {
    display: flex;
    gap: 10px;
    flex: 1; /* Allow the left side to take up available space */
  }
  
  /* Product Image */
  .product-image_customer img {
    width: 80px; /* Smaller image size */
    height: 80px; /* Smaller image size */
    object-fit: cover;
    border-radius: 8px; /* Optional: rounded image */
  }
  
  /* Product Info (Name and Description) */
  .product-info_customer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-between; */
  }
  
  .product-info_customer h3 {
    margin: 0;
    font-size: 14px; /* Smaller font size */
    font-weight: bold;
  }
  
  .product-info_customer p {
    margin-top: 5px;
    font-size: 12px; /* Smaller font size */
    color: #555;
  }
  
  /* Right Section: Product Actions */
  .product-actions_customer {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align the content to the right */
    justify-content: center;
    margin-top: 5px;
  }
  
  /* Delete Button */
  .delete_customer {
    cursor: pointer;
    color:black;
    font-size: 12px; /* Smaller delete button */
    font-weight: bold;
 
    margin-bottom: 10px; /* Reduced margin */
  }
  
  /* Quantity Counter */
  .quantity-counter_customer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quantity-counter_customer button {
    padding: 4px 8px; /* Smaller buttons */
    font-size: 14px; /* Smaller font size */
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    /* border-radius: 1px; */
    border-radius: 5px;
    margin: 0 4px; /* Reduced margin between buttons */
  }
  
  .quantity_customer {
    font-size: 14px; /* Smaller font size */
    font-weight: bold;
    margin: 0 4px; /* Reduced margin */
  }


  /* #########right side */
  /* Container for both left and right side */
.product_add_main_div_customer {
    display: flex;
   justify-content: space-between;
   /* align-items: center; */
    gap: 20px;
    /* width: 100%; */
    padding: 2rem;
  }
  
  /* Left side - Product Details */
  .product_left_side_customer {
    /* padding-right: 20px; */
    width:60vw;
    height: 80vh;
    overflow: scroll;
  }
  
  /* Right side - Price Details */
  .product_right_side_customer {
    width: 30vw; 
    /* padding: 20px; */
    /* border-left: 1px solid #ddd; */
    display: flex;
    flex-direction: column;
    /* margin-right: 10rem; */
    gap: 15px;
    border-left: 1px solid black;
  }
  
  /* Product Detail Section */
  .product_head_customer {
    margin-bottom: 20px;
  }
  
  .product-content_customer {
    display: flex;
    justify-content: space-between;
  }
  
  .product-left_customer {
    display: flex;
    gap: 15px;
    align-items: center;
    
  }
  
  .product-image_customer img {
    width: 100px; /* Adjust size as needed */
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .product-info_customer {
    flex: 1;
  }
  
  .product-actions_customer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .delete_customer {
    font-size: 14px;
    padding: 2px;
    color:black;
    cursor: pointer;
    /* border-radius: 50%; */
  }
  
  .quantity-counter_customer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .quantity_customer {
    font-size: 16px;
  }
  
  .minus_customer, .plus_customer {
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Price Detail Section */
  .price_detail_container_customer {
    padding: 20px;
   
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
    width: 100%;
    

  }
  
  .pice_detail_customer h4 {
    /* font-size: 18px; */
    margin-bottom: 15px;
  }
  
  .total_price_customer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    color:black;
  }
  .total_discount_customer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    color: #87BD41;
  }
  
  hr {
    margin: 20px 0;
    border-top: 1px solid #ddd;
  }
  
  .order-total_customer {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
  }
  
  .order-total_customer p {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  .total_discount_msg_customer p {
    margin: 0;
    font-size: 12px;
    color: rgba(144, 28, 126, 1);
    font-weight: bold;
    /* border:2px solid black; */
    margin-top: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba(199, 28, 165, 0.38);
  }
.continue_customer p{
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.continue_customer button{
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 30px; */
    padding: 1rem 2rem;
    border-radius: 10px;
    width: 100%;
    margin-top: 5px;
    background: #87BD41;
    border: none;
    color: white;
}
.continue-button_customer:active {
    transform: scale(0.95); /* Makes the button look pressed */
    background-color: #6ca22e;
}

.product-left-discount_customer{
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-around;
}

.product-left-discount_customer p{
  /* font-size: 10px; */
  text-align: center;
  color: rgba(144, 28, 126, 1);
}


@media screen and (max-width:1200px) {
  .product_left_side_customer{
    width:100%;
  }
  .product_right_side_customer{
    /* width: 100%; */
  }
  
}

@media screen and (max-width:768px) {
  .product_add_main_div_customer{
    flex-direction: column;
  }
  .product_right_side_customer{
    border:none;
    width: 100%;
  }
  .product-details_customer{
    flex-direction: column;
  }
  .product-actions_customer{
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .product-left_customer{
    align-items: center;
    justify-content: space-between;
  }
  .product_left_side_customer{
    width:100%;
  }
}



  
.userdiscountentercouponcode{
  margin-top: 1rem;
}
.applycodebtn{
  background-color: var(--color-blue);
  padding: 2px 7px;
  border-radius: 5px;
  color: white;
}