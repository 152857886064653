
.livenbrathe-p {
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: var(--mid-purple);
    color: var(--white);
    font-size: 2.0rem;
    text-align: center;
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.livenbrathe{
    z-index: 0;
    margin-top: 5rem;
}
.livenbrathe-p h3 {
    color: var(--white);
    font-size: 1.5rem;
    line-height: 1.6;
    padding-top: 16px;
    font-weight: 400;
    opacity: 0.8;
    font-family: 'poppins';
    text-align: center;
}

.livenbrathe-p h1 {
    color: var(--white);
    font-size: 3.2rem;
    line-height: 1.15;
    padding-top: 16px;
    font-weight: 600;
    font-family: 'poppins';
    text-align: center;
}



.livenbrathe-s {
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: var(--white);
    color: var(--dark-grey);
    font-size: 1.8rem;
    text-align: center;
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.livenbrathe-s h3 {
    color: var(--light-purple);
    font-size: 1.5rem;
    line-height: 1.4;
    padding-top: 16px;
    font-weight: 300;
    font-family: 'poppins';
    text-align: center;
    margin-top: 24px;
}

.livenbrathe-s h1 {
    color: var(--dark-grey);
    font-size: 3.2rem;
    line-height: 1.15;
    padding-top: 16px;
    font-weight: 600;
    font-family: 'poppins';
    text-align: center;
}
body {
    font-family: "Poppins", sans-serif;
  }
  
  .cards {
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
  }
  .cards .card {
    height: 440px;
    background: #fff;
    /* border-radius: 4px; */
    box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    min-width: 200px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: all 0.4s ease;
    margin: 20px 10px;
  }
  .cards .card:before {
    height: 190px;
    width: calc(100% + 100px);
    content: "";
    position: absolute;
    /* background-image: linear-gradient(to top, #00C2C1 0%, #00C2C1 100%); */
    background-image: linear-gradient(to top, var(--color-blue) 0%, var(--color-blue) 100%);
    border-radius: 4px 4px 100% 100%;
    transition: all 0.4s ease-out;
    top: 0;
  }
  .cards .card .close {
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    background-image: url("https://rafaelalvucas.github.io/assets/icons/misc/cross.svg");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    right: 0;
    margin: 10px;
    padding: 5px;
    transition: all 0.2s ease;
  }
  .cards .card .close:hover {
    background-size: 100%;
    opacity: 0.8;
  }
  .cards .card .arrow {
    display: none;
  }
  .cards .card article {
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .cards .card article h2 {
    color: white;
    margin: 0;
    padding: 40px 20px 10px 20px;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.5px;
  }
  .cards .card article .title {
    color: white;
    padding: 10px 20px;
    letter-spacing: 0.8px;
    transition: all 0.4s ease;
  }
  .cards .card article .desc {
    padding: 10px 30px;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    color: #666;
    height: 90px;
    transition: all 0.4s ease-out;
  }
  .cards .card article .pic {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 100%;
    margin: 20px 0;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
    transition: all 0.6s ease;
  }
  .cards .card article .pic img {
    width: 100%;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }
  .cards .card .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: white;
    z-index: 1;
  }
  .cards .card .actions .btn {
    border: 0;
    background-color: transparent;
    box-sizing: border-box;
    width: calc(50% - 1px);
    height: 36px;
    margin: 0;
    text-transform: uppercase;
    font-size: 10px;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
    color: #00C2C1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "Barlow", sans-serif;
    letter-spacing: 0.5px;
    background: rgba(68, 129, 235, 0.08);
  }
  .cards .card .actions .btn span {
    z-index: 1;
    opacity: 1;
    transition: all 0.4s ease-in-out;
  }
  .cards .card .actions .btn .icon {
    width: 10px;
    opacity: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
  }
  .cards .card .actions .btn:before {
    content: "";
    width: 100%;
    height: 0%;
    position: absolute;
    background: #00C2C1;
   
    transition: all 0.4s ease;
    bottom: 0;
    opacity: 0.2;
  }
  .cards .card .actions .btn:focus {
    outline: 0;
  }
  .cards .card .actions .btn:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .cards .card .actions .btn:hover span {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .cards .card .actions .btn:hover .icon {
    width: 22px;
    opacity: 1;
    transition: all 0.4s ease-in-out;
  }
  .cards .card .actions .btn:hover:nth-child(3) .icon {
    width: 18px;
  }
  .cards .card .actions .btn:hover:before {
    height: 100%;
  }
  .cards .card .actions .btn.clicked span {
    display: none;
  }
  .cards .card .actions .btn.clicked .icon {
    width: 22px;
    opacity: 1;
    animation: icon 0.5s ease forwards;
  }
  @keyframes icon {
    0% {
      width: 22px;
    }
    50% {
      width: 40px;
    }
    100% {
      width: 22px;
    }
  }
  .cards .card .actions .btn.clicked:before {
    opacity: 0.3;
    height: 100%;
  }
  .cards .card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
  }
  .cards .card:hover:before {
    height: 100%;
    border-radius: 4px;
  }
  .cards .card:hover .desc {
    color: white;
  }
  .cards .card:hover .pic {
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3);
  }
  .cards .card:hover .pic img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }
  .cards .card.closed {
    min-width: 120px;
    max-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s ease;
    cursor: pointer;
  }
  .cards .card.closed .title,
  .cards .card.closed .desc,
  .cards .card.closed .actions,
  .cards .card.closed .close {
    display: none;
  }
  .cards .card.closed h2 {
    padding: 0;
    height: 100%;
    transform: rotate(-90deg);
    width: 440px;
    z-index: 2;
    transition: all 0.6s ease;
  }
  .cards .card.closed .pic {
    border-radius: 100%;
    height: 400px;
    width: 400px;
    position: absolute;
    top: -20px;
    margin: 0;
    box-shadow: 0;
    transition: all 0.6s ease;
  }
  .cards .card.closed .pic img {
    object-fit: cover;
    height: 100%;
    transform: translateY(20px);
  }
  .cards .card.closed .pic:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
    transition: all 0.4s ease;
    transform: translateY(20px);
  }
  .cards .card.closed:before {
    height: 100%;
    border-radius: 4px;
  }
  .cards .card.closed .arrow {
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    bottom: 15px;
    padding: 5px;
    display: flex;
    justify-content: center;
    background-image: url("https://rafaelavlucas.github.io/assets/icons/misc/expand.svg");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.2s ease;
  }
  .cards .card.closed:hover .arrow {
    background-size: 100%;
    opacity: 0.6;
  }
  
  
  @media screen and (max-width: 1000px){
  
  .cards {
    width: 100%;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .cards .card {
    height: 440px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    min-width: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    transition: all 0.4s ease;
    margin: 10px 10px;
  }
  
  }
  
  @media screen and (max-width: 550px){  
    .cards .card article .pic {
      width: 120px;
      height: 120px;
      overflow: hidden;
      border-radius: 100%;
      margin: 20px 0;
      border-color: #fff;
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
      transition: all 0.6s ease;
    }
  
    .cards .card article .pic img {
      width: 100%;
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  
    .cards {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;
    }
    .cards .card {
      height: 440px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.1);
      max-width: 300px;
      min-width: 200px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: center;
      flex-direction: column;
      transition: all 0.4s ease;
      margin: 10px 10px;
    }
    
    }



