body{
    margin:0;
    height: 100%;
}

.ecom-layout-main{
    display: flex;
    height: 100vh;
}

.ecom-layout-sidebar{
    width: 15%;
    height: 100vh;
    background-color: var(--color-primary);
    color: var(--color-blue);
    transition: width 0.3s;
}

.ecom-collapsed{
    width: 5%;
}

.ecom-sidebar-header{
    display: flex;
    justify-content: flex-end;
    padding:10px;
}

.ecom-toggle-button{
    background:none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 2rem;
}

.ecom-nav-menu{
    display: flex;
    flex-direction: column;
}


.ecom-nav-items{
    display: flex;
    align-items: center;
    color: var(--color-blue);
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 600;
}

.ecom-nav-icon{
    font-size: 1.2rem;
    font-weight: 800;
}

.ecom-nav-items span{
    margin-left: 10px;
}

.ecom-nav-items.ecom-nav-active{
    color: white;
}

.ecom-nav-items.hover{
    color: yellow;
}

.ecom-content{
    flex: 1;
    overflow: scroll;
    background: linear-gradient(to top, #ddffb1, #eaf4dd);
}



@media screen and (max-width:992px) {    
    .ecom-layout-sidebar{
        height: 100%;        
    }

    .ecom-nav-items span {
        display: none;
    }

    .ecom-nav-items {
        justify-content: center; 
    }

    .ecom-sidebar-header {
        justify-content: center; 
    }
}
