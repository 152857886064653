
#consult_services{    
    display: flex;
    flex-wrap: wrap;
    /* width: 18rem; */
    background-color: var(--color-blue);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .card{
    background-color:'whitesmoke';
} */

.card:hover {
    transform: scale(1.05); /* Increase the size of the card */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    cursor: pointer; /* Change the cursor to a pointer */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add smooth transition */
  }

  @media screen and (max-width:650px) {
    .card-body img{
        margin: 1px auto;
    }
    .card2{
        height: 5rem;
    }
    .card-body {
        padding: 20px;
    }


    .cardcontent{
        display: block;
        margin: 0;
        width: 80%;
        justify-content: center;
        align-items: center;
       }
  }

.card2{
    display: flex;
    background-color: transparent;
    color: white;
    border-radius: 2px solid white;
    
}



   .cardcontent{
    display: flex;
    flex-wrap: wrap;
    margin: 3rem;
    justify-content: center;
    align-items: center;
   }

   .card-body{
    padding: 2rem;
    height: 25rem;
   }

   
@media screen and (max-width:1024px){
    .card-body{
        height: auto;
        padding: 10px 30px
    }
}

