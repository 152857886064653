.payment_pg_customer{
    padding: 20px;
    
}
.step-navigation_customer {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .step-item_customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    position: relative;
  }
  
  .step_customer {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
  }
  
  .step-label_customer {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
  
  /* Adjusted line */
  .line_customer {
    width: 40px;
    height: 2px;
    
    background-color: #ccc;
    position: relative;
    top: -10px; /* Aligns the line with the middle of the step circle */
    z-index: -1; /* Positions line behind the circles */
  }

  .left_payment_customer{
    width: 50%;
    
    margin-left: 20px;
    
  }
  .product_right_side_customer {
    /* width: 30%; */
    /* padding: 20px; */
    /* border-left: 1px solid #ddd; */
    display: flex;
    flex-direction: column;
    /* margin-right: 10rem; */
    gap: 15px;
   border-left: 1px solid black;
}
/* Price Detail Section */
.price_detail_container_customers {
    padding: 20px;   
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
    width: 100%;
  }
  
  .pice_detail_customer h4 {
    /* font-size: 18px; */
    margin-bottom: 15px;
  }
  
  .total_price_customer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    color:black;
  }
  .total_discount_customer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    color: #87BD41;
  }
  
  hr {
    margin: 20px 0;
    border-top: 1px solid #ddd;
  }
  
  .order-total_customer {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
  }
  
  .order-total_customer p {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  .total_discount_msg_customer p {
    margin: 0;
    font-size: 12px;
    color: rgba(144, 28, 126, 1);

    font-weight: bold;
    /* border:2px solid black; */
    margin-top: 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba(199, 28, 165, 0.38);
    ;
  }
.continue_customer p{
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.continue_customer button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 10px;
    width: 100%;
    color: white;
    margin-top: 5px;
    background: #87BD41;
    border: none;
}
.continue-button_customer:active {
  background: #649e2c; /* Slightly darker shade when clicked */
  transform: translateY(2px); /* Button appears to be pressed down */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect on click */
}
.product_right_side_customer {
    width: 30%; /* Right side takes 35% of the container */
    /* padding: 20px; */
    /* border-left: 1px solid #ddd; */
    display: flex;
    flex-direction: column;
    /* margin-right: 10rem; */
    gap: 15px;
   border-left: 1px solid black;
}
.main_payment_div_customer{
    
    display: flex;
   /* justify-content: space-between; */
    gap: 20px;
    width: 100%;
    padding: 20px;
  } 

.left_payment__customer{
 
  border: 2px solid rgb(227, 223, 223);
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* justify-content: space-around; */
  gap:20px;
  padding: 2rem;
  width: 80%;
  margin: 1rem auto;
  border-radius: 8px;
}

.left__payment{
  width:50%;
}

.left-payment-online{
  display: flex;
  border: 2px solid rgb(196, 194, 194);
  width: 100%;
  height: 40%;
  padding: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.left-payment-online input {
  width:3rem;
}

.left-payment-online span{
  font-size:20px;
}


  @media screen and (max-width:768px) {
    .product_right_side_customer {
      width: 100%;
      margin: 0;
      border: none;
    } 
    .main_payment_div_customer{
      flex-direction: column;
    }
    .left__payment{
      width: 100%;
    }
    .left_payment__customer {
      width: 100%;
    }
  }
