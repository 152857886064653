.ecom-product-detail-main-section{
    display: flex;
    flex-direction: column;
    gap:2rem;
}

.ecom-product-detail-container{
    /* width: 90%; */
    min-height: 80vh;
    background-color: white;
    border: 2px solid rgb(209, 207, 207);
    border-radius: 8px;
    padding:2rem;
}

.ecom-product-detail-image-section{
    /* border: 2px solid blue; */
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    justify-content: flex-start;
    align-items: center;
}

.ecom-product1-image {
    max-height: 136px;
    max-width: 136px; 
    min-height: 136px; 
    min-width: 136px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ecom-product1-image img {
    height: 100%;
    width: 100%;
    object-fit: cover; /* Use 'cover' to maintain aspect ratio and fill the box */
}


.ecom-product-detail-images{
    /* border: 1px solid #000; */
  
}
.ecom-product-detail-edit-image-text{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ecom-product-detail-edit-image-text p{
    cursor: pointer;
}

.ecom-product-detail-addimage-text p{
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    color: var(--color-blue);
}





.ecom-product-detail-section {
    width: 50vw;
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ecom-product-left-detail-section,
.ecom-product-right-detail-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
}
.ecom-product-left-detail-section span{
    /* border: 2px solid blue; */
    width:5rem;
}

.ecom-product-detail-section p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
}


.ecom-product-detail-section input {
    width: 140px;
    height: 30px;
    border-radius: 7px;
    border: 2px solid #C9C7C7;
    padding: 0 10px;
}
.ecom-product-detail-section input:focus {
    outline: none;
}

.final-price {
    color: green;
    margin-left: auto; /* Aligns Final Price to the right */
}


.discount-table {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; 
}

.discount-table p {
    display: flex;
    align-items: center;
}

.discount-table span {
    padding-right: 1rem;
    width: 120px; 
    text-align: right; 
}

.discount-table input {
    flex: 1; 
    padding: 0.5rem;
    font-size: 1rem;
}





.ecom-product-detail-desc-text{
    border: 2px solid grey;
    border-radius: 8px;
    width: 50vw;
    height: 20vh;
    padding:0 0 10px 20px;
    position: relative;
    overflow: scroll;
}
.ecom-product-detail-desc-text::-webkit-scrollbar {
    display: none; 
}

/* Optional: for Firefox */
.ecom-product-detail-desc-text {
    scrollbar-width: none; 
}


.ecom-product-detail-btns{
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 2rem;
    align-items: center;
}

.ecom-product-detail-cancel-btn{
    background-color: red;
    color: white;
    border-radius: 10px;
    padding:6px 30px;
    cursor: pointer;
    font-family: 'Poppins';
    font-weight: bold;
    border: none;
}
.ecom-product-detail-save-btn{
    background-color: #50BA4E;
    color: white;
    border-radius: 10px;
    padding:6px 32px;
    cursor: pointer;
    font-family: 'Poppins';
    font-weight: bold;
    border: none;
}

@media screen and (max-width:768px) {
    .ecom-product-detail-main-section{
        justify-content: center;
        align-items: center;
    }
    .ecom-product-detail-container{

    }
    .ecom-product-detail-image-section{
        justify-content: center;
       
    }
    .ecom-product-detail-btns{
        justify-content: center;
        gap:10px;
    }
   
    
}


