.ecom-user-detail-header{
    margin-top: 1rem;
}


.ecom-user-detail-container{ 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ecom-user-detail-main{
    margin:2rem;
    min-height: 80vh;
    border: 2px solid rgb(209, 207, 207);
    width: 70vw;
    border-radius: 8px;
    background-color: white;
    padding: 2rem ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.ecom-user-details-div{
    margin: 2rem;
}

.ecom-user-back{
    cursor: pointer;
    text-decoration: none;
}

.ecom-user-detail-section{
    display: flex;
    justify-content: space-between;
    padding: 0 6rem 0 0;
    position: relative;
}

.ecom-user-left-section-detail p {
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.ecom-user-left-section-detail p b {
    max-width: 100%; 
    overflow-wrap: break-word; 
    display: block; 
}

.ecom-user-right-profile{
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    border: 2px solid grey;
}

.ecom-user-right-profile-detail{
    text-align: center;
}

.ecom-user-detail-btns{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap:2rem;
    padding: 0 2rem;
}

.ecom-user-approve-btn{
    background-color: #50BA4E;
    color: white;
    border-radius: 10px;
    padding:6px 30px;
    cursor: pointer;
    font-family: 'Poppins';
    font-weight: bold;
    border: none;
}

.ecom-user-discard-btn{
    background-color: red;
    color: white;
    border-radius: 10px;
    padding:6px 30px;
    cursor: pointer;
    font-family: 'Poppins';
    font-weight: bold;
    border: none;
}

@media screen and (max-width:1024px) {
    .ecom-user-detail-section{
        flex-direction: column;
    }
    .ecom-user-detail-section{
        padding:0;
    }
    .ecom-user-right-section-detail{
        order: -1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }
    .ecom-user-detail-main {
        margin: 0;
    }
    
}

@media screen and (max-width:768px) {
    .ecom-user-detail-main {
        /* width: 50vw; */
    }
    .ecom-user-detail-btns{
      gap:0.5rem;
    }
}