#home2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home2__tag img{
    height: 3rem;
    width: 3rem;  
    margin-top: 2rem;
}

.home2__text p{
    font-family: 'Poppins',"Sans-serif";
    font-weight: bold;
    
}

.home2__text img{
    height: 5rem;
    width: 20rem;
}

.home2__left{
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
}

.home2__left .home2__text{
    margin:2rem;
}

.home2__logo{
    margin-top: 2rem;
}

.home2__right img{
    height: 15rem;
    width: 55rem;
}

.below_div_home2{
    background-color: var(--color-primary);
    height: 0.8rem;
    margin-top: -0.8rem;
}

@media screen and (max-width:1444px) {
    #home2{
        display: block;
    }
    .home2__right img{
        width: 100%;
        margin: 0;
        height: auto;
    }
}

@media screen and (max-width:990px) {
    #home2{
        display: block;
    }
    .home2__right img{
        width: 100vw;
    }
}

@media screen and (max-width:650px){
    #home2{
        display: block;
    }
    .home2__right{
        margin: 0;
    }
    
    .home2__right img{
        width: 100vw;
        height: auto;
        margin: 0;
    }

    .home2__logo{
        width: 50vw;
        margin-left:-6rem;
    }
}





