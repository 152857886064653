/* body{
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
font-size: 14px;
line-height: 1.42857143;
font-family: 'Open Sans', sans-serif;
text-rendering: optimizeLegibility !important;
-webkit-font-smoothing: antialiased !important;
color: #636b72;
box-sizing: border-box;
} */

.contact__container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.contact__mainmap{

}

.contact_details{
    /* float: right;
    margin-left:27em; */
}

/* .contact_details h3{
    margin-bottom: 3rem;
} */
#contact_parent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

#contact_parent .gmap{
    width: 50%;
    height: auto;
    padding: 12px;
    
}

#contact_parent iframe{
    width: 50vw;
    height: 80vh;
    border: 0;
}

.col-md-4 {
    max-width: 100%;

}

.contact_details{
    margin-left: 10%;
}


@media screen and (max-width:900px){

    #contact_parent{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
    }

    #contact_parent .gmap{
        width: 100%;
    }

    #contact_parent iframe{
        width: 100%;
    }

    .contact_details{
        margin: 10%;

    }

}
