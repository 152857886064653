/* html,body{
    height: 100%;
    margin: 0;
}

.ecom-register-main{
    background: linear-gradient(to top,#b4e870,#e3f3ce);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ecom-register{    
    border: 2px solid red;
    display: flex;
    gap: 10%;
}

.ecom-reg-leftside{
    border:2px solid green;
    display: flex;
    align-items: center;
    justify-content: center;
}



.ecom-reg-leftside img{
    height: 80%;
}


.ecom-reg-rightdiv{
    background: linear-gradient(to top, #929fd2,#e3f3ce);
    border: 2px solid black;
    height: 100%;
    width: 30rem;
    border-radius: 8px;
}
 */


 /* html, body {
    height: 100%;
    margin: 0;
    font-family: 'Inter';
  } */
  
  .ecom-register-main {
    background: linear-gradient(to top, #ddffb1, #e3f3ce);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    margin-top: 2rem;
  }
  
  .ecom-register {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
  
  .ecom-reg-leftside {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ecom-reg-leftside img {
    /* height: 70%; */
  }
  
  .ecom-reg-rightdiv {
    background: linear-gradient(to top, #c7cde8, #e3f3ce);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .ecom-reg-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
  
  .ecom-reg-tabs span {
    cursor: pointer;
    /* font-size: 1.2rem; */
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
  }
  


  .ecom-reg-tabs .active {
    font-weight: 600;
    color: white;
    border-bottom: 2px solid #4caf50;
  }
  
  .ecom-reg-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;    
  }
  

  .ecom-reg-input-group {
    display: flex;
    gap: 0.7rem;
  }
  
  .ecom-reg-ip{
    border: none;
    padding: 5px 10px;
    border-radius: 8px;
    /* font-weight: 500; */
  }

  .ecom-reg-ip:focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    color: #C9C7C7;
    /* font-weight: 600; */
  }
  

  .ecom-reg-otp-btn, .verify-btn, .register-btn, .ecom-login-btn {
    padding: 0.7rem;
    font-weight: 600;
    background-color:#5067FF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 1rem;
  }
  
  .otp-btn:hover, .verify-btn:hover, .register-btn:hover, .ecom-login-btn:hover {
    background-color: #388e3c;
  }
  
  .ecom-password-section {
    margin-top: 1rem;
  }

  .ecom-reg-password-div{
   display: flex;
   flex-direction: column;
   gap:1rem;
  }


  .ecom-forgot-password{
    color: red;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
  }

  @media screen and (min-width:700px) and (max-width:1024px) {
    .ecom-register{
      width: 100%;
    }
  }


  @media screen and (max-width:700px){
     .ecom-register{
        width: 100vw;
        height: 100vh;
    }
    .ecom-reg-leftside {
        display: none;
    }
    .ecom-reg-rightdiv{
        height: 100vh;
        width: 100vw;
    }

    .ecom-reg-input-group{
      flex-direction: column;
    }    
  }
