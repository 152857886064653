* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  overflow-x: hidden;
}

.dashboard_customer {
  display: flex;
  /* height: 100vh; */
  width: 100vw; /* Full width */
  margin: 0 auto;
  padding: 30px;
  margin-top: 50px;
  /* border: 2px solid black; */
}

.left-div_customer {
  width: 40%; /* Adjust to take part of the screen width */
  background-color: black;
  color: white;

  margin-left: 50px;
  margin-right: 20px;
 border: 2px solid black;
 
}


.left-div_customer{
  position: relative;
  border: 2px solid rgb(139, 138, 138);
  border-radius: 8px;
  overflow: hidden;
}

.right-div_customer {
  width: 60%; /* Adjust to fill remaining width */
  background-color: #ecf0f1;
  color: black;
 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows boxes to wrap onto the next line */
  gap: 10px;
}

.box_customer {
  position: relative;
  width: 350px; /* Two boxes per row with spacing */
  height: 270px;
  border: 2px solid rgb(120, 118, 118);
  border-radius: 8px;
  overflow: hidden;
 
}

.box_customer img ,.left-div_customer img {
  width: 100%; /* Adjusts image to fit within the box */
  height: 100%; /* Ensures image covers the box fully */
  object-fit: cover;
}

.overlay_customer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(22, 33, 78, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  opacity: 1;
}

.overlay_customer h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.overlay_customer p {
  font-size: 0.9em;
  margin-bottom: 10px;
}

.overlay_customer button {
  padding: 8px 15px;
  background-color:transparent;
  color: white;
  border: 2px solid white;
  border-radius: 4px;
  cursor: pointer;
}



.overlay_customer button:hover {
  background-color: #a3a6a7;
}

.left-div_customer {
  width: 40%;
  /* position: relative; */
  border: 2px solid rgb(118, 116, 116);
  border-radius: 8px;
  overflow: hidden;
 
}

.left-divv img {
  width: 100%; /* Full width of the left-div */
  height: 100%;
  opacity: 0.6; /* Make image appearance lighter */
  object-fit: cover;
}


@media  screen and (max-width:1310px) {
  .dashboard_customer{
    flex-direction: column;
    gap:20px;
  }
  .left-div_customer{
    width: 100%;
    margin: 0;
  }
  .right-div_customer{
    width: 100%;
  }
}

@media screen and (max-width:768px) {
  .left-div_customer{
    width: 100%;
    margin: 0;
  }
  .right-div_customer{
    width: 100%;
  }
  .dashboard_customer{
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap:10px;
  }
  .box_customer {
    
  }
  
}