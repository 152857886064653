/* Container for the left and right sections */
.main_subdropdown_div_customer {
  display: flex;
  padding: 20px;
  margin-top: 60px;
}

.paginationclass{
 display: flex;
 justify-content: flex-end;
 align-items: center;
  font-size: 1.3rem;
  padding-right: 2rem;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
}
.rightdivforshowingproductdetails{
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
}


/* Left section styling */
.main_left_div_customer {
  width: 25%;
  padding: 20px;
  border-right: 2px solid #ddd; /* Add left border */
}


/* Right section styling */
.main_right_div_customer {
  /* width: 70%; */
  padding: 20px;
}

.led_head_para_customer p {
  font-size: 16px;
  padding-top: 10px;
}

/* Dropdown container styling */
.dropdown_container_rawmaterial_customer {
  margin: 10px 0;
}

.dropdown_rawmaterial_customer {
  width: 260px;
  height: 35px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  color: #333;
  margin-top: 30px;
  cursor: pointer;
}

.filter_customer {
  border: 1px solid #ddd;
  width: 250px;
  padding: 10px;
  margin-top: 30px;
  font-family: Arial, sans-serif;
}

.filter-title_customer {
  font-weight: bold;
  font-size: 1.1em;
  margin: 0;
}

.filter-divider_customer {
  border: none;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.filter-option_customer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 1em;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.filter-option_customer p {
  margin: 0;
}

.filter-option_customer span {
  font-size: 0.9em;
  color: #555;
}

.checkbox-group_customer {
  margin-left: 20px;
  padding: 5px;
}

.checkbox-group_customer label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
}

.checkbox-group_customer input {
  /* margin-right: 10px; */
  width: 20%;
}

.slider-container_customer {
  padding: 10px 0;
}

.price-slider_customer {
  width: 100%;
  height: 8px;
  background: #ddd;
  outline: none;
  border-radius: 5px;
  transition: 0.3s ease;
  -webkit-appearance: none;
  appearance: none;
}

.slider-labels_customer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.price-display_customer {
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.rawmaterial-container_customer {
  display: flex;
  justify-content: space-between;
}

.left-side_customer {
  width: 60%;
  padding: 20px;
}

.header_customer h2 {
  font-size: 24px;
}

.sort-dropdown_customer select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}




.filters_customer {
  margin-top: 20px;
}

.filter-item_customer {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 14px;
}

.filter-item_rating_customer {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

.main_right_div_raw_customer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.material_card_content_customer {
  padding: 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  margin: 20px;
  width: 220px;
  background: #FFFFFF;
  border: 1px solid #9a9797;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
}

.pro_material_img_customer img {
  height: 130px;
  width: 100%;
  display: flex;
  padding: 10px;
  object-fit: contain;
}

.material_product_content_customer {
  width: 100%;
  text-align: center;
}

.product_name_customer {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  text-overflow: ellipsis;
}

.price_section_customer {
  display: flex;
  justify-content: center;
  align-items: start;
  font-size: 12px;
  color: #555;
  gap: 5px;
  margin-bottom: 5px;
}

.original_price_customer {
  text-decoration: line-through;
  color: #888;
}

.discounted_price_customer {
  font-weight: bold;
  color: #333;
}

.discount_percentage_customer {
  color: green;
}

.rating_customer {
  background-color: #33C71C;
  opacity: 83%;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 20px;
  width: 50px;
  height: 30px;
}

.view_more_button_customer {
  width: 100%;
  background-color: #33C71C;
  opacity: 83%;
  color: white;
  border-radius: 5px;
  padding: 8px 0;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}



.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  flex-direction: column;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; /* Light gray */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.ratingandreview-conatiner{
  margin-top: 2rem;
}

.ratingandreview-conatiner textarea{
  /* width: 5rem; */
}

@media screen and (max-width:1200px) {
  .main_left_div_customer{
    border: none;
    width: 32%;
  }
  
}





@media screen and (max-width:768px) {
  .product-details-section_customer {
    margin: 2rem;
  }
  .main_subdropdown_div_customer{
    flex-direction: column;
  }  
  .main_left_div_customer{
    width:100%;
    justify-content: center;
    align-items: center;
  }
  .dropdown_rawmaterial_customer{
    margin: 0;
    width: 100%;
  }
  .filter_customer {
    width: 100%;
    margin: 0;
  }
}


.download_catalog{
  /* border: 1px solid black; */
  background: green;
  color: white;
  padding: 10px;
  border-radius: 10px;
}